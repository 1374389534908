import { ColumnProps } from 'antd/es/table';
import { TableFilters } from 'src/applications/base/types/table';
import React from 'react';
import {
    SimilarityFilters,
    SimilarityModel,
} from '../../../../../types/document/documents';
import { Link } from 'react-router-dom';
import ClientRoute from '../../../../features/utils/client-routing/ClientRoute/ClientRoute';
import { getSearchFilterColumnProps } from '../../../../utils/tableColumns';
import { ReactComponent as IdentitySources } from 'src/applications/base/images/navigation/identity-sources.svg';
import { ReactComponent as Loader } from 'src/applications/base/images/loader.svg';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utils/constants';

export const getSimilarityColumns = (
    filters: Partial<TableFilters<SimilarityFilters>>,
    documentRouting: ClientRoute,
    onCellClick: (id: string) => void
): ColumnProps<SimilarityModel>[] => [
    {
        title: 'Документ',
        key: 'title',
        dataIndex: 'title',
        ...getSearchFilterColumnProps('Название документа'),
        filteredValue: filters.title,
        width: '20%',
        render: (title: string, { id }) => (
            <Link
                className={''}
                to={documentRouting.getUrl({
                    id,
                })}
            >
                {title}
            </Link>
        ),
    },
    {
        title: 'Тип документа',
        key: 'type',
        dataIndex: 'type',
        width: '20%',
        render: (type: string) => <>{type || '-'}</>,
    },
    {
        title: 'Дата создания',
        key: 'created',
        dataIndex: 'created',
        width: '20%',
        render: (created: string) => (
            <>{created ? moment(created).format(DATE_FORMAT) : '-'}</>
        ),
    },
    {
        title: 'Степень сходства',
        key: 'score',
        dataIndex: 'score',
        width: '20%',
        render: (score) => <>{(score * 100).toFixed(2)} %</>,
    },
    {
        title: 'Текстовые пересечения',
        key: '_similarity',
        dataIndex: '_similarity',
        width: '20%',
        sorter: true,
        render: (_similarity, row) => (
            <>
                {_similarity === null ? (
                    <Loader />
                ) : (
                    <span>
                        {(_similarity * 100).toFixed(2)} %{' '}
                        {_similarity > 0 && (
                            <IdentitySources
                                onClick={() => onCellClick(row.id)}
                            />
                        )}{' '}
                    </span>
                )}
            </>
        ),
    },
];
