import { ISearchStore } from 'src/applications/base/app/features/search/stores/SearchStore/ISearchStore';
import {
    ApiFilterInfoRecord,
    ApiHierarchicalFilterValue,
    ApiRangeFilterType,
    DisplayFilterValue,
    DocumentSearchResponseBody,
    FilterValuesContainer,
    isDisplayFilterValue,
    isHierarchicalFilterValue,
    isStringFilterValue,
    RangeFilterValuesContainer,
    SearchRequestArgument,
    SearchTab,
    StringFilterValue,
} from 'src/applications/base/types/search/searchAPI';
import { ISearchResultStore } from 'src/applications/base/app/features/search/stores/SearchResultStore/ISearchResultStore';
import { ISearchRequestBuilder } from 'src/applications/base/app/features/search/misc/request-builder/ISearchRequestBuilder';
import { ITabStore } from 'src/applications/base/app/features/search/stores/TabStore/ITabStore';
import { IActiveFilterStore } from 'src/applications/base/app/features/search/stores/SearchFiltersStore/IActiveFilterStore';
import {
    IMainSearchSettings,
    MainSearchSettingsState,
} from 'src/applications/base/app/features/search/stores/SearchSettingsStore/interfaces/IMainSearchSettings';
import { IFilterStore } from 'src/applications/base/app/features/search/stores/SearchFiltersStore/IFilterStore';
import { IFilterValueStore } from 'src/applications/base/app/features/search/stores/SearchFiltersStore/IFilterValueStore';
import { IActiveTabStore } from 'src/applications/base/app/features/search/stores/TabStore/IActiveTabStore';
import { ISearchEventsListener } from 'src/applications/base/app/features/search/misc/search-events-listener/ISearchEventsListener';
import { SearchResultsToProcess } from 'src/applications/base/app/features/search/stores/SearchStore/SearchResultsToProcess';
import { CountedItems } from 'src/applications/base/app/features/search/stores/SearchResultStore/CountedItems';

export type TabStoreType = ITabStore<SearchTab>;
export type ActiveTabStoreType = IActiveTabStore<SearchTab>;

export type ActiveFilterStoreType = IActiveFilterStore<ActiveFilterValue>;

export type FilterStoreType = IFilterStore<FilterInfoRecord>;
export type FilterValueStoreType = IFilterValueStore<
    FilterValue,
    SearchRequestArgument,
    FilterValuesContainer
>;

export type SearchSettingsStoreType = IMainSearchSettings<
    SearchTab,
    FilterStoreType,
    FilterInfoRecord,
    FilterValueStoreType,
    FilterValue,
    ActiveFilterStoreType
>;

export type SearchResultsToProcessType = SearchResultsToProcess<
    DocumentSearchResponseBody,
    MainSearchSettingsState
>;

export type SearchResultsStoreType = ISearchResultStore<
    CountedItems,
    SearchResultsToProcessType
>;

export type SearchStoreType = ISearchStore<
    SearchResultsStoreType,
    SearchSettingsStoreType
>;

export type SearchRequestBuilderType = ISearchRequestBuilder<
    SearchSettingsStoreType,
    SearchRequestArgument
>;

export type SearchEventListenerType = ISearchEventsListener<
    SearchStoreType,
    MainSearchSettingsState,
    SearchRequestArgument
>;

//------------------------------- ФИЛЬТРЫ -------------------------
//ТИП И ИНФОРМАЦИЯ О ФИЛЬТРЕ
export enum FilterType {
    Select = 'select',
    HierarchicalSelect = 'hierarchicalSelect',
    Year = 'year',
}

export type FilterInfoRecord = ApiFilterInfoRecord;

//ТИПЫ ЗНАЧЕНИЙ ФИЛЬТРОВ
export interface SelectFilterValues {
    values: Record<string, StringFilterValue>;
    topValueList: string[];
}
export interface DisplayFilterValues {
    values: Record<string, DisplayFilterValue>;
    topValueList: string[];
}

export interface HierarchicalFilterValue extends ApiHierarchicalFilterValue {
    childrenCodes: string[];
}

export interface HierarchicalFilterValues {
    values: Record<string, HierarchicalFilterValue>;
    topValueList: string[];
}

export type RangeFilterValues = RangeFilterValuesContainer;

//так хранятся "текущие" значения фильтров, которые получаются на каждый поисковый запрос

//только значения селект фильтров
export type AllSelectFilterValues =
    | SelectFilterValues
    | HierarchicalFilterValues
    | DisplayFilterValues;
//все значения
export type FilterValue = AllSelectFilterValues | RangeFilterValues;

export const isSelectFilterValues = (
    vals: FilterValue
): vals is SelectFilterValues => {
    const selectVals = vals as SelectFilterValues;
    const index = selectVals.topValueList?.length ? selectVals.topValueList[0] : 0
    const firstVal = selectVals.values?.[index];
    return firstVal && isStringFilterValue(firstVal);
};
export const isDisplaySelectFilterValue = (
    vals: FilterValue
): vals is DisplayFilterValues => {
    const selectVals = vals as DisplayFilterValues;
    const index = selectVals.topValueList?.length ? selectVals.topValueList[0] : 0
    const firstVal = selectVals.values?.[index];
    return firstVal && isDisplayFilterValue(firstVal);
};

export const isHierarchicalFilterValues = (
    vals: FilterValue
): vals is HierarchicalFilterValues => {
    const selectVals = vals as HierarchicalFilterValues;
    const index = selectVals.topValueList?.length ? selectVals.topValueList[0] : 0
    const firstVal = selectVals.values?.[index];
    return firstVal && isHierarchicalFilterValue(firstVal);
};

export const isRangeFilterValues = (
    vals: FilterValue
): vals is RangeFilterValues => Object.prototype.hasOwnProperty.call(vals,'rangeType');

export const isYearRangeFilterValues = (
    vals: FilterValue
): vals is RangeFilterValues =>
    (vals as RangeFilterValues).rangeType === ApiRangeFilterType.RangeYear;

//ТИПЫ АКТИВНЫХ ЗНАЧЕНИЙ ФИЛЬТРОВ
export type ActiveFilterValue = string | string[] | RangeFilterActiveValue;

export type RangeFilterActiveValue = Partial<RangeFilterValues>;
