import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import queryString from 'qs';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { ProfileSlice } from 'src/applications/base/types/stores';
import { RouteComponentProps } from 'react-router-dom';
import { ProfileRouteQueryString } from 'src/applications/base/types/routing';
import { ProfileHeader } from 'src/applications/base/app/react-app/pages/profile/ProfileHeader';
import { ProfileTabs } from 'src/applications/base/app/react-app/pages/profile/ProfileTabs';

import classes from './Profile.module.scss';

type ProfileProps = RouteComponentProps;

export const Profile: FC<ProfileProps> = observer(
    ({ location, children, history }) => {
        const parsedQs = queryString.parse(location.search, {
            ignoreQueryPrefix: true,
        }) as ProfileRouteQueryString;
        const personId = parsedQs.personId;

        const { profiles } = useStores<ProfileSlice>();

        const profile = profiles.getProfile(personId);

        useEffect(() => {
            personId && profiles.fetchProfile(personId);
        }, [personId, profiles]);

        return (
            <div>
                <ProfileHeader
                    profile={profile}
                    nameInitials={profiles.getNameInitials(personId)}
                />
                <ProfileTabs
                    className={classes.tabs}
                    history={history}
                    location={location}
                />
                <div className={classes.children}>{children}</div>
            </div>
        );
    }
);
