import { observer } from 'mobx-react-lite';
import { SearchRoute } from 'src/applications/base/types/routing';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { FC, useEffect, useRef } from 'react';
import { useService } from '@redtea/react-inversify';
import {
    COMMON_DEP,
    LOGO_DEP,
} from 'src/applications/base/inversify/dependencyIdentifiers';
import { useHistory } from 'react-router-dom';
import { useUnmount, useUpdateEffect } from 'react-use';
import { SearchSlice } from 'src/applications/base/types/stores';
import Suggester from 'src/applications/base/app/react-app/components/Suggester/Suggester';

import classes from './Main.module.scss';
import { MainLogoComponent } from 'src/applications/base/types/components';

export const Main: FC = observer(() => {
    const history = useHistory();
    const { search }: SearchSlice = useStores();
    const { search: searchRoute } = useService<SearchRoute>(
        COMMON_DEP.ClientRouting
    );
    const Logo = useService<MainLogoComponent>(LOGO_DEP.MainLogoComponent);

    const searchAlias = search.getLastSuccessfulAlias();

    useEffect(() => {
        search.settings.fetchSearchSettings();
        search.setVectorBoostOverride(0);
    }, [search]);

    //нужно выполнить clearUp если переходим по ссылкам на другие страницы, но не если начинаем поиск
    const shouldClearUpSearchStore = useRef(true);

    useUpdateEffect(() => {
        if (typeof searchAlias === 'string') {
            shouldClearUpSearchStore.current = false;
            history.push(searchRoute.getUrl({ alias: searchAlias }));
        }
    }, [searchAlias, history, searchRoute]);

    useUnmount(() => {
        if (shouldClearUpSearchStore.current) {
            search.clearUp();
        }
    });

    return (
        <div className={classes.main}>
            <div className={classes.content}>
                <div className={classes.logo}>
                    <Logo />
                </div>
                <div className={classes.search}>
                    <Suggester
                        className={classes['search-panel']}
                        visualStyle='main'
                        dropdownProps={{
                            popupStyle: { width: '100vw' },
                            popupAlign: {
                                points: ['tc', 'bc'],
                            },
                        }}
                    />
                </div>
            </div>
            <div className={classes.background}></div>
        </div>
    );
});
