import {
    CommonDocumentInfo,
    DocumentHighlights,
    DocumentProperties,
} from 'src/applications/base/types/document/documents';
import { ISearchService } from 'src/applications/base/app/features/search/services/SearchService/ISearchService';

/*-------------------------- ПОИСКОВЫЙ ЗАПРОС ------------------------------*/

export type SearchServiceType = ISearchService<
    SearchRequestArgument,
    DocumentSearchResponseBody
>;

export interface SearchRequestArgument {
    queryString: string;
    skip: number;
    limit: number;
    searchTabCode: string;
    vectorBoostOverride?: number;
    sortBy?: {
        [fieldName: string]: 'ASC' | 'DESC';
    };
    userFilters?: SearchRequestFilter[];
    disableSpellcheck: boolean;
}

export interface SearchRequestFilter {
    type: 'BaseFilter';
    code: string;
    value: FilterValuesContainer;
    field: string;
}

export type LSpellcheckSearched = 'Original' | 'Spellchecked' | 'Both';

/*-------------------------- ОТВЕТ ------------------------------*/

export interface SearchResponseBody<TProperties, THighlights> {
    spellcheckSearchMode: LSpellcheckSearched;
    spellcheckedQuery: string;
    totalCount: number;
    items: NKCSearchHit<TProperties, THighlights>[];
}

export type SearchChunk = {
    score: number;
    text: string[];
};

export interface NKCSearchHit<TP, TH> {
    id: string;
    highlights: TH;
    chunks: SearchChunk[];
    source?: NKCDocumentSearchHitSource<TP>;
}

export interface NKCDocumentSearchHitSource<T> extends CommonDocumentInfo {
    id: string;
    modifiedAt?: string;
    rootId?: string;
    parentId?: string;
    properties?: T;
    shortContent?: string;
}

export type DocumentSearchResponseBody = SearchResponseBody<
    DocumentProperties,
    DocumentHighlights
>;
export type DocumentSearchHit = NKCSearchHit<
    DocumentProperties,
    DocumentHighlights
>;

/*-------------------------- АВТОПОДСКАЗКИ ------------------------------*/

export interface AutocompletionRequestBody {
    query: string;
    limit?: number;
}

export interface AutocompletionResponseBody {
    items: AutocompletionResponseItem[];
}

export interface AutocompletionPosition {
    start: number;
    length: number;
}

type SuggestionType = 'Completion';

export interface AutocompletionResponseItem {
    documentId: number;
    highlighted: string;
    length: number;
    offset: number;
    score: number;
    suggestionType: SuggestionType;
    text: string;
    _renderText?: string;
}

/*-------------------------- ПОИСКОВЫЕ ВКЛАДКИ ------------------------------*/

export interface SortOption {
    code: string;
    fields: string[];
    label: string;
    defaultOrder: 'DESC' | 'ASC';
}

export interface SearchTab {
    id: string;
    name: string;
    code: string;
    isDefault: boolean;
    searchTarget: SearchTarget;
    hidden: boolean;
    description?: string;
    vectorBoost?: number | null;
    sortOptions?: {
        defaultOption: string | null;
        defaultOrder: string | null;
        options: SortOption[];
    };
}

export enum SearchTarget {
    DOC = 'DOC',
    EMPLOYEE = 'EMPLOYEE',
}

/*-------------------------- ФИЛЬТРЫ ------------------------------*/

//ФИЛЬТРЫ
export interface SimpleFilterSettings {
    hidden: boolean;
    sort: number;
    type: string;
    default?: FilterValuesContainer;
}

export interface ApiFilterInfoRecord {
    id: string;
    code: string;
    name: string;
    system: string;
    searchTarget: SearchTarget;
    field: string;
    sourceId: string;
    settings: SimpleFilterSettings;
}

//ФИЛЬТРЫ ПО ВКЛАДКАМ
export interface SearchTabFilterRecord {
    id: string;
    searchTabId: string;
    filterInfoId: string;
    minPercent: number;
    hidden: boolean;
    priority: number;
    alwaysVisible: boolean;
    filterType: string;
}

export enum AccessFilterType {
    System = 'SystemFilter',
    User = 'UserFilter',
}

//ЗНАЧЕНИЯ
export enum ApiFilterType {
    Select = 'SelectFilterValue',
    Range = 'RangeFilterValue',
    Empty = 'EmptyFilterValue',
}

export enum ApiRangeFilterType {
    RangeDate = 'Date',
    RangeYear = 'Year',
}

export enum FilterValueType {
    String = 'StringFilterValue',
    Hierarchical = 'HierarchicalCatalogFilterValue',
    Display = 'DisplayFilterValue',
}

export interface StringFilterValue {
    type: FilterValueType.String;
    value: string;
    docCount?: number;
}

export interface DisplayFilterValue {
    type: FilterValueType.Display;
    value: string;
    docCount?: number;
    code: string;
}

export interface ApiHierarchicalFilterValue {
    type: FilterValueType.Hierarchical;
    code: string;
    value: string;
    childCount: number;
    parentCode?: string;
    docCount: number;
}

export type ApiFilterValue =
    | StringFilterValue
    | ApiHierarchicalFilterValue
    | DisplayFilterValue;

export interface SelectFilterValuesContainer {
    values: ApiFilterValue[];
    type: ApiFilterType.Select;
}

export interface RangeFilterValuesContainer {
    gt?: number;
    gte?: number;
    lt?: number;
    lte?: number;
    rangeType: ApiRangeFilterType;
    type: ApiFilterType.Range;
}

export type FilterValuesContainer =
    | SelectFilterValuesContainer
    | RangeFilterValuesContainer;

export const isHierarchicalFilterValue = (
    value: ApiFilterValue
): value is ApiHierarchicalFilterValue =>
    value.type === FilterValueType.Hierarchical;

export const isStringFilterValue = (
    value: ApiFilterValue
): value is StringFilterValue => value.type === FilterValueType.String;

export const isDisplayFilterValue = (
    value: ApiFilterValue
): value is StringFilterValue => value.type === FilterValueType.Display;

//ВЫПАДАЮЩИЕ СПИСКИ
export enum AuthType {
    Basic = 'Basic',
    Kerberos = 'KERBEROS',
    Ntlm = 'NTLM',
    ApiKey = 'ApiKey',
    Token = 'Token',
}
