/* eslint-disable no-template-curly-in-string */
import { ConfigProviderProps } from 'antd/lib/config-provider';
import { SearchTarget } from 'src/applications/base/types/search/searchAPI';

// https://ant.design/components/form/#validateMessages
export const adminFormValidationMessages: Exclude<
    ConfigProviderProps['form'],
    undefined
>['validateMessages'] = {
    required: 'Укажите ${label}',
    whitespace: '${label} не может состоять из пробельных символов',
};

export enum AdminTablePaginationPageSize {
    SMALL = 10,
    MEDIUM = 20,
    LARGE = 50,
}

export const searchTargetDisplayName: Record<SearchTarget, string> = {
    DOC: 'Документ',
    EMPLOYEE: 'Персона',
};

export const crawlerCron2Title = new Map([
    ['0 0 */4 ? * *', 'Раз в 4 часа'],
    ['0 0 */8 ? * *', 'Раз в 8 часов'],
    ['0 0 0 ? * *', 'Раз в сутки'],
    ['0 0 0 ? * 0', 'Раз в неделю'],
]);

export const schedulerCron2Title = new Map([
    ['0 0 * ? * *', 'Раз в час'],
    ['0 0 */12 ? * *', 'Раз в 12 часов'],
    ['0 0 0 ? * *', 'Раз в сутки'],
    ['0 0 0 ? * 0', 'Раз в неделю'],
]);

export enum FontAwesomeIcons {
    EDIT = 'far fa-edit',
    TRASH = 'far fa-trash-alt',
    CHECK = 'fas fa-check',
    MINUS = 'fas fa-minus',
    FOLDER = 'fas fa-folder',
    FILE = 'fas fa-file',
    CHEVRON = 'fas fa-chevron-right',
    CHEVRON_BACK = 'fas fa-chevron-left',
    CHEVRON_DOWN = 'fas fa-chevron-down',
    RESTORE = 'fas fa-redo',
    TIMES = 'fas fa-times',
    USER = 'fas fa-user-circle',
    CIRCLE = 'fas fa-circle',
    RUN = 'fas fa-play',
    ENVELOPE = 'fas fa-envelope',
    PLUS = 'fas fa-plus',
    UPLOAD = 'fas fa-upload',
    DOWNLOAD = 'fas fa-download',
    KEY = 'fas fa-key',
    USER_PLUS = 'fas fa-user-plus',
    BARS = 'fas fa-bars',
    CARET_RIGHT = 'fas fa-caret-right',
    CARET_DOWN = 'fas fa-caret-down',
    CARET_UP = 'fas fa-caret-up',
    INFO = 'fas fa-info-circle',
    QUESTION = 'fas fa-question-circle',
    SEARCH = 'fas fa-search',
    CALENDAR = 'fas fa-calendar',
    PAUSE = 'far fa-pause-circle',
    BAN = 'fas fa-ban',
    FILTER = 'fas fa-filter',
    CIRCLE_CLOSE = 'fas fa-times-circle',
    PLAY = 'fas fa-play',
}

export const antdTableFormLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 4 },
};

export const antdFormLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};

export const antdSidepageFormLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

export const FULL_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const SERVER_FORMAT_TIME_Z = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const JOBS_POLLING_INTERVAL = 60000;

export const ACTIVE_NOT_ACTIVE = new Map([
    ['1', 'Активен'],
    ['0', 'Не активен'],
]);

export const PRINCIPALS_TYPE = new Map([
    ['User', 'Пользователь'],
    ['Group', 'Группа'],
]);
export const EMPTY_FILTERS = {
    skip: 0,
    limit: 10,
    sortBy: [],
    filters: [],
};
