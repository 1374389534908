import React from 'react';
import { DocumentProperty } from '../DocumentProperty';
import classes from './DocumentPropertiesView.module.scss';
import { DocumentPropertyProps } from '../DocumentProperty/DocumentProperty';

type DocumentPropertyRenderingData = Omit<
    DocumentPropertyProps,
    'className'
> & { name: string };

interface DocumentPropertiesViewProps {
    properties?: DocumentPropertyRenderingData[];
}

export const DocumentPropertiesView: React.FC<DocumentPropertiesViewProps> = ({
    properties,
}) => {
    return (
        <>
            {properties?.map((property) => (
                <DocumentProperty
                    className={classes['property']}
                    key={property.name}
                    {...property}
                />
            ))}
        </>
    );
};
