import { FC } from 'react';
import { Avatar } from '@nkc-frontend/cat-design';
import { Profile } from 'src/applications/base/types/profiles';
import { observer } from 'mobx-react-lite';

import classes from './ProfileHeader.module.scss';

export interface ProfileHeaderProps {
    nameInitials: string;
    profile: Profile | null;
}

export const ProfileHeader: FC<ProfileHeaderProps> = observer(
    ({ profile, nameInitials }) => {
        if (!profile) {
            return null;
        }

        return (
            <article className={classes['profile-header']}>
                <div className={classes.avatar}>
                    <Avatar alt={nameInitials} size={'M'} />
                </div>
                <div className={classes.info}>
                    <div className={classes.name}>{profile.fullName}</div>
                    <a
                        href={`mailto:${profile.email}`}
                        title={profile.email ?? ''}
                        className={classes.email}
                    >
                        {profile.email}
                    </a>
                </div>
            </article>
        );
    }
);
