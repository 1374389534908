import classNames from 'classnames';
import { FC, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@nkc-frontend/cat-design';

import { useService } from '@redtea/react-inversify';
import {
    COMMON_DEP,
    LOGO_DEP,
} from 'src/applications/base/inversify/dependencyIdentifiers';
import { MainRoute } from 'src/applications/base/types/routing';
import Suggester from 'src/applications/base/app/react-app/components/Suggester/Suggester';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { SearchSlice } from 'src/applications/base/types/stores';
import { Navigation } from 'src/applications/base/app/react-app/components/common/Navigation';
import { UserNavigation } from 'src/applications/base/app/react-app/components/common/UserNavigation';
import { BaseHeader } from 'src/applications/base/app/react-app/components/common/headers/BaseHeader';

import classes from './SearchHeader.module.scss';
import { observer } from 'mobx-react-lite';
import { HeaderLogoComponent } from 'src/applications/base/types/components';

export interface SearchHeaderProps {
    className?: string;
    logoAndSearchBarClassName?: string;
    searchBarContainerClassName?: string;
}

export const SearchHeader: FC<SearchHeaderProps> = observer((props) => {
    const {
        className,
        logoAndSearchBarClassName,
        searchBarContainerClassName,
    } = props;

    const { main } = useService<MainRoute>(COMMON_DEP.ClientRouting);
    const Logo = useService<HeaderLogoComponent>(LOGO_DEP.HeaderLogoComponent);

    const { search } = useStores<SearchSlice>();

    const ref = useRef<HTMLElement>(null);

    const onTabChange = (tabCode: string) => {
        search.settings.changeTab(tabCode);
        if(search.getVectorBoostOverride()){
            search.setVectorBoostOverride(search.settings.getActiveTab()?.vectorBoost || .5);
        }
        search.load();
    };

    return (
        <BaseHeader ref={ref} className={classNames(classes.header, className)}>
            <div className={classes.content}>
                <div
                    className={classNames(
                        classes['logo-search-bar'],
                        logoAndSearchBarClassName
                    )}
                >
                    <div className={classes['logo-wrapper']}>
                        <Link to={main.getUrl()}>
                            <Logo className={classes.logo} />
                        </Link>
                    </div>
                    <div className={searchBarContainerClassName}>
                        <Suggester
                            className={classes['search-bar']}
                            visualStyle='search'
                            dropdownProps={{
                                getTriggerDOMNode: () =>
                                    ref.current as HTMLElement,
                                popupStyle: {
                                    width: '100%',
                                    zIndex: 2000,
                                },
                            }}
                        />
                        <Tabs
                            active={search.settings.getActiveTabCode()}
                            onChange={onTabChange}
                        >
                            {search.settings.getTabList()?.map((tab) => (
                                <Tab key={tab.id} value={tab.code}>
                                    {tab.name}
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div>
                <div className={classes.menus}>
                    <Navigation className={classes.navigation} />
                    <UserNavigation />
                </div>
            </div>
        </BaseHeader>
    );
});
