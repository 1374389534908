import React, { useCallback, useState, useEffect } from 'react';
import Button from 'antd/es/button';
import moment from 'moment';
import { DatePicker, Tag } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { DateFormat } from 'src/applications/base/types/table';

import classes from './FilterDatePicker.module.scss';

export interface FilterDatePickerProps extends FilterDropdownProps {
    dateType: DateFormat;
    isUtc?: boolean;
}

export const FilterDatePicker: React.FC<FilterDatePickerProps> = React.memo(
    (props) => {
        const {
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters: clear,
            dateType,
            isUtc,
        } = props;

        const [from, setFrom] = useState<moment.Moment | null>(null);
        const [to, setTo] = useState<moment.Moment | null>(null);

        const dateToString = useCallback(
            (date: moment.Moment | null) =>
                date
                    ? dateType === 'timestamp'
                        ? date.unix().toString()
                        : date.toISOString()
                    : '',
            [dateType]
        );

        const onStartChange = useCallback(
            (date: moment.Moment | null) => {
                const from =
                    date &&
                    (isUtc ? date.utc().startOf('day') : date.startOf('day'));

                if (from && to && from.isAfter(to)) {
                    setTo(from);
                    setFrom(to);

                    return;
                }

                setFrom(from);
            },
            [to, isUtc]
        );

        const onEndChange = useCallback(
            (date: moment.Moment | null) => {
                const to =
                    date &&
                    (isUtc ? date.utc().endOf('day') : date.endOf('day'));

                if (from && to && to.isBefore(from)) {
                    setFrom(to);
                    setTo(from);

                    return;
                }

                setTo(to);
            },
            [from, isUtc]
        );

        const confirmFilters = useCallback(() => {
            if (from || to) {
                setSelectedKeys &&
                    setSelectedKeys([dateToString(from), dateToString(to)]);
                confirm && confirm();
            }
        }, [confirm, setSelectedKeys, from, to, dateToString]);

        const clearFilters = useCallback(() => {
            setFrom(null);
            setTo(null);
            clear && clear();
        }, [clear]);

        useEffect(() => {
            if (Array.isArray(selectedKeys) && selectedKeys.length === 0) {
                setFrom(null);
                setTo(null);
            }
        }, [selectedKeys]);

        return (
            <div className={classes['filter-date-picker']}>
                <div className={classes['picker-block']}>
                    <DatePicker
                        className={classes['start-picker']}
                        format='DD.MM.YYYY'
                        placeholder='Начальная дата'
                        onChange={onStartChange}
                        value={from ?? undefined}
                    />
                    <DatePicker
                        className={classes['end-picker']}
                        format='DD.MM.YYYY'
                        placeholder='Конечная дата'
                        onChange={onEndChange}
                        value={to ?? undefined}
                    />
                </div>
                <div className={classes['buttons']}>
                    <Tag
                        className={classes['tag']}
                        color='blue'
                        onClick={() => {
                            setFrom(
                                isUtc
                                    ? moment().utc().startOf('day')
                                    : moment().startOf('day')
                            );
                            setTo(
                                isUtc
                                    ? moment().utc().endOf('day')
                                    : moment().endOf('day')
                            );
                        }}
                    >
                        Сегодня
                    </Tag>
                    <Tag
                        className={classes['tag']}
                        color='blue'
                        onClick={() => {
                            setFrom(
                                isUtc
                                    ? moment().utc().startOf('week')
                                    : moment().startOf('week')
                            );
                            setTo(
                                isUtc
                                    ? moment().utc().endOf('week')
                                    : moment().endOf('week')
                            );
                        }}
                    >
                        Текущая неделя
                    </Tag>
                    <Button
                        className={classes['search-button']}
                        type='primary'
                        onClick={confirmFilters}
                        size='small'
                    >
                        Поиск
                    </Button>
                    <Button
                        className={classes['reset-button']}
                        onClick={clearFilters}
                        size='small'
                    >
                        Сброс
                    </Button>
                </div>
            </div>
        );
    }
);
