import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import {
	DocumentSlice,
	PendingRequestSlice,
} from '../../../../../types/stores';
import { useComputed } from '../../../hooks/useComputed';
import {
	FilterTable,
	useFilterTableFilters,
} from '../../../components/common/admin/tables/FilterTable';
import { TablePaginationConfig } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import {
	SimilarityFilters,
	SimilarityModel,
} from '../../../../../types/document/documents';
import { getSimilarityColumns } from './getSimilarityColumns';
import { useService } from '@redtea/react-inversify';
import { DocumentCardRoute } from '../../../../../types/routing';
import { COMMON_DEP } from '../../../../../inversify/dependencyIdentifiers';
import classes from './SimilarityList.module.scss';

export const SimilarityList: FC<{ documentId: string }> = observer(({ documentId }) => {
	const { documents: documentStore, pendingRequest } = useStores<
		DocumentSlice & PendingRequestSlice
	>();
	const [paginationInit, setPagination] = useState({
		current: 1,
		pageSize: 10,
	});
	const { document: documentRouting } = useService<DocumentCardRoute>(COMMON_DEP.ClientRouting);
	const identityList = documentStore.similarityFilteredList || [];
	
	const totalCount = useComputed(() => documentStore.similarityCount, [documentStore]);
	
	const { filters, handleTableChange, resetFilters } =
		useFilterTableFilters<SimilarityFilters>({ title: [] });
	
	const startSearch = useCallback(
		(
			{ current, pageSize }: TablePaginationConfig,
			filters: SimilarityFilters,
			sorter: SorterResult<any> | SorterResult<any>[],
		) => {
			const body: any = {};
			if (current && pageSize) {
				body.skip = (current - 1) * pageSize;
				body.limit = pageSize;
			}
			const { field, order } = Array.isArray(sorter)
				? sorter[0]
				: sorter;
			body.sortBy = [
				{
					field: order ? field?.toString() : '',
					order:
						order === 'descend'
							? 'DESC'
							: !order
								? 'DESC'
								: 'ASC',
				},
			];
			body.filters = filters;
			body.documentId = documentId;
			
			documentStore.setActiveFilters(body);
		},
		[documentStore, documentId],
	);
	
	const onPageChange = useCallback(
		(page: number, pageSize?: number) => {
			setPagination({ current: page, pageSize: pageSize || 10 });
		},
		[setPagination],
	);
	const [selectedRowKey, setSelectedRowKey] = useState('');
	const rowClassName = (row: SimilarityModel) => {
		const rowClasses = [];
		if (row.id === selectedRowKey) {
			rowClasses.push(classes['selected-row']);
		}
		return rowClasses.join(' ');
	};
	const onCellClick = useCallback((sourceDocumentId: string) => {
			documentStore.openSideBar(documentId, sourceDocumentId);
			setSelectedRowKey(sourceDocumentId);
		},
		[documentStore, documentId],
	);
	
	const columns = useMemo(
		() => getSimilarityColumns(
			filters,
			documentRouting,
			onCellClick,
		),
		[filters, documentRouting, onCellClick],
	);
	
	useEffect(() => {
		if (!documentStore.sourceDocumentId) {
			setSelectedRowKey('');
		}
	}, [documentStore.sourceDocumentId]);
	
	const pagination = {
		current: paginationInit.current,
		pageSize: paginationInit.pageSize,
		onChange: onPageChange,
		total: totalCount,
	};
	
	const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
		handleTableChange(
			pagination,
			filters,
			sorter as
				| SorterResult<SimilarityFilters>
				| SorterResult<SimilarityFilters>[],
			extra as any,
		);
		startSearch(
			pagination,
			filters,
			sorter,
		);
	};
	const onResetFiltersClick = () => {
		resetFilters();
		documentStore.resetFilters();
		documentStore.updateFilteredSimilarityList();
	};
	return (
		<>
			<FilterTable
				rowClassName={rowClassName}
				className={classes['similarityTable']}
				loading={pendingRequest.isPending('DOCUMENT_VIEWED')}
				pagination={pagination}
				columns={columns}
				dataSource={identityList}
				rowKey={(tab) => tab.id}
				onChange={onChange}
				onResetFiltersClick={onResetFiltersClick}
			/>
		</>
	);
});
