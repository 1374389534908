import { FC } from 'react';
import classNames from 'classnames';
import { DocumentSnippetComponent } from 'src/applications/base/types/components';
import { DOCUMENT_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { Button } from '@nkc-frontend/cat-design';
import { useService } from '@redtea/react-inversify';
import { DocumentSnippet } from 'src/applications/base/types/document/documents';
import { observer } from 'mobx-react-lite';

import classes from './DocumentListWithPaging.module.scss';
import {
	CountedItems,
} from 'src/applications/base/app/features/search/stores/SearchResultStore/CountedItems';
import { SearchSettingsStore } from '../FilterPanel/FilterPanel/FilterPanel';

export interface DocumentListDocStore {
	getSnippet(id: string): DocumentSnippet | null;
	
	requestSetFavorite(id: string, favorite: boolean): void;
}

export interface DocumentListSearchStore {
	searchResults: {
		getCurrentResult(): CountedItems | null;
	};
	settings: SearchSettingsStore
	loadMore(): void;
	loadMoreDocument(): void;
}

export interface DocumentListWithPagingProps {
	search?: DocumentListSearchStore;
	documents?: DocumentListDocStore;
	
	className?: string;
	resultsClassName?: string;
	snippetClassName?: string;
	loadMoreBtnClassName?: string;
}

export const DocumentListWithPaging: FC<DocumentListWithPagingProps> = observer(
	(props) => {
		const {
			search,
			documents,
			className,
			snippetClassName,
			loadMoreBtnClassName,
			resultsClassName,
		} = props;
		const DocumentSnippet = useService<DocumentSnippetComponent>(
			DOCUMENT_DEP.DocumentSnippet,
		);
		
		if (!search || !documents) {
			return null;
		}
		
		const currentTabResults = search.searchResults.getCurrentResult();
		const resultCount = currentTabResults?.totalCount ?? 0;
		const documentResultList = currentTabResults?.items;
		
		return (
			<div className={className}>
				<div
					className={classNames(classes.results, resultsClassName)}
					data-testid={'search-results'}
				>
					{Array.isArray(documentResultList) &&
						documentResultList.map((resultId) => (
							<DocumentSnippet
								className={snippetClassName}
								key={resultId}
								documentSnippet={documents.getSnippet(resultId)}
								onLike={(like) => {
									documents.requestSetFavorite(
										resultId,
										like,
									);
								}}
							/>
						))}
				</div>
				{Array.isArray(documentResultList) &&
					documentResultList.length > 0 &&
					resultCount > documentResultList.length && (
						<div data-testid={'load-more-btn'}>
							<Button
								className={classNames(
									loadMoreBtnClassName,
									classes['load-more-btn'],
								)}
								type={'button'}
								visualStyle={'secondary'}
								onClick={()=> {
									const activeTab = search?.settings.activeTabStore?.getActiveTab();
									activeTab?.code !== 'favorites'
										? props.search?.loadMore()
										: props.search?.loadMoreDocument();
								}}
							>
								Показать еще
							</Button>
						</div>
					)}
			</div>
		);
	},
);
