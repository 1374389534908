import { parseISO, format as dateFnsFormat } from 'date-fns';
import { ru } from 'date-fns/locale';

export class DateFormatter {
    private constructor(private date: Date) {}

    static fromIsoString(isoString: string): DateFormatter {
        return new this(parseISO(isoString));
    }

    private format(
        formatString: string,
        options?: Parameters<typeof dateFnsFormat>[2]
    ): string | undefined {
        return !isNaN(this.date.valueOf())
            ? dateFnsFormat(this.date, formatString, options)
            : undefined;
    }

    toDocumentSnippet(): string | undefined {
        return this.format('dd.MM.yyyy');
    }

    toAdminNewsColumn(): string | undefined {
        return this.format('dd.MM.yyyy HH:mm');
    }

    toNotificationCard(): string | undefined {
        return this.format('d MMMM yyyy', { locale: ru });
    }

    toNotificationCardTime(): string | undefined {
        return this.format('H:mm');
    }

    toDocumentCard(): string | undefined {
        return this.format('dd MMMM yyyy', { locale: ru });
    }

    toDocumentCardTree(): string | undefined {
        return this.format('dd.MM.yyyy HH:mm', { locale: ru });
    }
}
