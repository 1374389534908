import React from 'react';
import classNames from 'classnames';

import classes from './CheckMark.module.scss';
import { FontAwesomeIcons } from 'src/applications/base/app/utils/constants';

export interface ICheckMarkProps {
    check: boolean;
}

export const CheckMark: React.FC<ICheckMarkProps> = (props) =>
    props.check ? (
        <i
            className={classNames(
                FontAwesomeIcons.CHECK,
                classes['check-mark']
            )}
        />
    ) : (
        <i
            className={classNames(
                FontAwesomeIcons.MINUS,
                classes['check-mark']
            )}
        />
    );
