import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch } from '@nkc-frontend/cat-design';

import classes from './MainSearchSummary.module.scss';
import {
	SearchSummaryWithSettings,
} from 'src/applications/base/app/react-app/components/common/search-result/SearchSummaryWithSettings';
import {
	SearchSummaryWithSettingsProps,
} from 'src/applications/base/app/react-app/components/common/search-result/SearchSummaryWithSettings/SearchSummaryWithSettings';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import { SearchSlice } from '../../../../../types/stores';
import { useComputed } from '../../../hooks/useComputed';

export type MainSearchSummaryProps = Pick<
	SearchSummaryWithSettingsProps,
	'search' | 'className'
>;

export const MainSearchSummary: FC<MainSearchSummaryProps> = observer(
	({ search, className }) => {
		const { search: searchStore } = useStores<SearchSlice>();
		
		if (!search) {
			return null;
		}
		useEffect(() => searchStore.setVectorBoostOverride(0), [searchStore]);
		const vectorBoost = useComputed(() => searchStore.settings.getActiveTab()?.vectorBoost || .5, [searchStore]);
		
		return (
			<div className={className}>
				<SearchSummaryWithSettings
					search={search}
					sorting
					renderSummary={(count, highlightClass) => (
						<>
							<span>Результат поиска </span>
							<span className={highlightClass}>
                                {'документов'}: {count ?? 0}
                            </span>
						</>
					)}
					extraSettingsNode={
						<Switch
							className={classes.personalization}
							onChange={(val) => {
								searchStore.setVectorBoostOverride(
									val ? vectorBoost : 0,
								);
							}}
						>
							Поиск по смыслу
						</Switch>
					}
				/>
			</div>
		);
	},
);
