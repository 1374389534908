import React, { ComponentType, FC, PropsWithChildren, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { AuthorizationSlice } from 'src/applications/base/types/stores';

export const withAuthorization = <TProps extends any>(
    WrappedComponent: ComponentType<TProps>
): FC<TProps> =>
    observer((props: PropsWithChildren<TProps>) => {
        const { authorization }: AuthorizationSlice = useStores();

        useEffect(() => {
            if (!authorization.isAuthorized) {
                authorization.authorize();
            }
        }, [authorization.isAuthorized, authorization]);

        return authorization.isAuthorized ? (
            <WrappedComponent {...props} />
        ) : null;
    });
