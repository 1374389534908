import classNames from 'classnames';
import { FC } from 'react';

import classes from './PageTitle.module.scss';

export interface PageTitleProps {
    className?: string;
}

export const PageTitle: FC<PageTitleProps> = ({ children, className }) => {
    return <h1 className={classNames(classes.title, className)}>{children}</h1>;
};
