import { Button } from '@nkc-frontend/cat-design';
import Icon from '@nkc-frontend/cat-design/dist/components/Icon';
import classNames from 'classnames';

import classes from './LikeButton.module.scss';

interface LikeButtonProps {
    className?: string;
    liked?: boolean;
    onLike?(liked: boolean): void;
    type: 'icon' | 'button';
}

export const LikeButton: React.FC<LikeButtonProps> = ({
    type,
    className,
    liked,
    onLike,
}) => {
    return type === 'icon' ? (
        <div
            onClick={() => onLike?.(!liked ?? false)}
            className={classNames(className, classes['icon'])}
        >
            {liked ? (
                <Icon icon='favorite-fill' />
            ) : (
                <Icon icon='favorite-stroke' />
            )}
        </div>
    ) : (
        <Button
            visualStyle='plain-link'
            before={
                liked ? (
                    <Icon
                        className={classes['button-icon']}
                        icon='favorite-fill'
                    />
                ) : (
                    <Icon
                        className={classes['button-icon']}
                        icon='favorite-stroke'
                    />
                )
            }
            className={className}
            onClick={() => onLike?.(!liked ?? false)}
        >
            Добавить в избранное
        </Button>
    );
};
