import { Observable } from 'rxjs';
import { IHeadersProvider } from 'src/applications/base/app/features/utils/api/HeadersProvider/IHeadersProvider';
import { IBaseApiUrlProvider } from 'src/applications/base/app/features/utils/api/BaseApiUrlProvider/IBaseApiUrlProvider';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { decorate, inject, injectable } from 'inversify';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import {
    AdminSearchTabFilterCreateForm,
    AdminSearchTabFilterListArgs,
    AdminSearchTabFilterUpdateForm,
    AdminTabFilterServiceType,
    SearchTabFilterInfo,
} from 'src/applications/base/types/admin/adminTabFilters';
import { ApiFilterInfoRecord } from 'src/applications/base/types/search/searchAPI';

class _AdminTabFilterService implements AdminTabFilterServiceType {
    constructor(
        private headersProvider: IHeadersProvider,
        private baseUrlProvider: IBaseApiUrlProvider
    ) {}

    fetchFilterInfoList(): Observable<ApiFilterInfoRecord[]> {
        return ajax
            .get(
                this.baseUrlProvider.joinUrl('/filters/info'),
                this.headersProvider.jsonHeaders()
            )
            .pipe(map(({ response }) => response));
    }

    fetchList(
        args: AdminSearchTabFilterListArgs
    ): Observable<SearchTabFilterInfo[]> {
        return ajax
            .get(
                this.baseUrlProvider.joinUrl(
                    `admin/tabs/filters/${args.tabId}`
                ),
                this.headersProvider.jsonHeaders()
            )
            .pipe(map(({ response }) => response));
    }

    create(
        tabFilter: AdminSearchTabFilterCreateForm
    ): Observable<SearchTabFilterInfo> {
        return ajax
            .post(
                this.baseUrlProvider.joinUrl('admin/tabs/filters'),
                tabFilter,
                this.headersProvider.jsonHeaders()
            )
            .pipe(map(({ response }) => response));
    }

    edit(tabFilter: AdminSearchTabFilterUpdateForm): Observable<void> {
        return ajax
            .put(
                this.baseUrlProvider.joinUrl(
                    `admin/tabs/filters/${tabFilter.id}`
                ),
                tabFilter,
                this.headersProvider.jsonHeaders()
            )
            .pipe(map(() => undefined));
    }

    delete(id: string): Observable<void> {
        return ajax
            .delete(
                this.baseUrlProvider.joinUrl(`admin/tabs/filters/${id}`),
                this.headersProvider.jsonHeaders()
            )
            .pipe(map(() => undefined));
    }
}

export const AdminTabFilterService = /*__PURE__*/ (() => {
    decorate(injectable(), _AdminTabFilterService);
    decorate(
        inject(COMMON_DEP.HeadersProvider) as any,
        _AdminTabFilterService,
        0
    );
    decorate(
        inject(COMMON_DEP.BaseApiUrlProvider) as any,
        _AdminTabFilterService,
        1
    );
    return _AdminTabFilterService;
})();
