import React, { FC } from 'react';
import {
    NerTitles,
    SomeNer,
} from '../../../../../types/document/documents';
import { observer } from 'mobx-react-lite';
import classes from '../DocumentData/DocumentData.module.scss';
import { generateNerText } from '../common';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import { DocumentSlice } from '../../../../../types/stores';

export const DocumentBlock: FC<{ someNer: SomeNer[] }> =
    observer(({ someNer }) => {
        const { documents: documentStore } = useStores<DocumentSlice>();
        if (!someNer?.length) {
            return <></>;
        }
        const title = NerTitles[someNer[0].type];
        
        return (
            <div className={classes['documentData__block']}>
                <div className={classes['documentData__block-title']}>
                    {title}
                </div>
                <div className={classes['documentData__block-content']}>
                    {someNer.map((s: SomeNer) => {
                        return (
                            <div
                                className={classes['documentData__link']}
                                key={s.hash}
                                onClick={()=> {
                                    documentStore.selectNer.next(s.hash)
                                }}
                            >
                                {generateNerText(s)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    });
