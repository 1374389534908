/**
 * Created by Lkarmelo on 13.10.2017.
 */
import queryString from 'qs';

import { generatePath } from 'react-router-dom';

type TSubRoutesConfig = Record<string, ClientRoute>;

type ParamMap<T extends string> = {
    [paramName in T]: string | number | boolean | undefined;
};
type QueryParamMap<T extends string> = {
    [paramName in T]?: string | number | boolean | string[];
};

type ChildrenParams<T> = T extends ClientRoute<infer P> ? P : never;
type ChildrenQuery<T> = T extends ClientRoute<any, infer Q> ? Q : never;

export default class ClientRoute<
    TParams extends string = never,
    TQuery extends string = never,
    SubRoutesConfig extends TSubRoutesConfig = TSubRoutesConfig
> {
    //сложный тип, который просто означает, что в дочерних роутах должны быть включены параметры родительского роута,
    //то есть например в роуте
    //const fooRoute = new ClientRoute('/foo/:fooId', {bar: new ClientRoute('/bar/:barId')})
    //у fooRoute.subRoutes.bar должны быть в параметрах и fooId и barId
    subRoutes:
        | {
              [P in keyof SubRoutesConfig]: ClientRoute<
                  TParams | ChildrenParams<SubRoutesConfig[P]>,
                  TQuery | ChildrenQuery<SubRoutesConfig[P]>
              >;
          }
        | Record<string, never>;

    routingPattern: string;

    constructor(pattern: string, subRoutesConf?: SubRoutesConfig) {
        this.routingPattern = pattern;

        if (subRoutesConf) {
            this.subRoutes = subRoutesConf;
            this.updateSubRoutesPattern();
        } else {
            this.subRoutes = {};
        }
    }

    private concatenateSubRoutePatternWithParent(
        subRoutesPattern: string
    ): string {
        return `${this.routingPattern}${subRoutesPattern}`;
    }

    private updateSubRoutesPattern(): void {
        Object.entries(this.subRoutes).forEach(([_, subRoute]) => {
            subRoute.routingPattern = this.concatenateSubRoutePatternWithParent(
                subRoute.routingPattern
            );
        });
    }

    getUrl(params?: ParamMap<TParams>, query?: QueryParamMap<TQuery>): string {
        const url = generatePath(this.routingPattern, params);

        if (typeof query === 'undefined') {
            return url;
        }

        const paramStr = queryString.stringify(query, {
            allowDots: true,
            arrayFormat: 'repeat',
            addQueryPrefix: true,
        });

        return `${url}${paramStr}`;
    }
}
