import { FC, useEffect } from 'react';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import {
    DocumentSlice,
    FavoriteSlice,
} from 'src/applications/base/types/stores';
import { observer } from 'mobx-react-lite';
import { SearchResult } from 'src/applications/base/app/react-app/components/common/search-result/SearchResult';
import { SearchSummaryWithSettings } from 'src/applications/base/app/react-app/components/common/search-result/SearchSummaryWithSettings';
import { useUnmount } from 'react-use';

export interface FavoriteProps {
    className?: string;
}

export const Favorite: FC<FavoriteProps> = observer(() => {
    const { favorite, documents } = useStores<FavoriteSlice & DocumentSlice>();

    useEffect(() => {
        favorite.settings.fetchSearchSettings();

        favorite.loadNewDocuments();
    }, [favorite]);

    useUnmount(() => favorite.clearUp());

    return (
        <SearchResult
            search={favorite}
            documents={documents}
            topNode={
                <SearchSummaryWithSettings
                    sorting
                    renderSummary={(count, highlightClass) => (
                        <span className={highlightClass}>
                            Документов: {count}
                        </span>
                    )}
                />
            }
        />
    );
});
