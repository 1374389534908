import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import classNames from 'classnames';
import { NKCNotification } from 'src/applications/base/types/notifications';
import { Truncate } from '@nkc-frontend/cat-design';

import classes from './NotificationCard.module.scss';
import { useCheckOverflowOnImageLoad } from 'src/applications/base/app/react-app/pages/profile/pages/notifications/NotificationCard/useCheckOverflowOnImageLoad';
import { DateFormatter } from 'src/applications/base/app/utils/DateFormatter';

export interface NotificationCardProps {
    notification: NKCNotification;
    className?: string;
    truncateClassName?: string;
    dateBlockClassName?: string;
    mainBlockClassName?: string;
    markRead(id: string): void;
}

export const NotificationCard: FC<NotificationCardProps> = observer((props) => {
    const {
        notification,
        markRead,
        dateBlockClassName,
        mainBlockClassName,
        className,
        truncateClassName,
    } = props;

    const dateFormatter = DateFormatter.fromIsoString(notification.created);

    const formattedDate = dateFormatter.toNotificationCard();
    const formattedTime =
        formattedDate && dateFormatter.toNotificationCardTime();

    const [cardContentId, truncateRef] = useCheckOverflowOnImageLoad();

    return (
        <article
            className={classNames(classes.card, className, {
                [classes.card_unread]: notification.unread,
            })}
            onClick={() => {
                notification.unread && markRead(notification.id);
            }}
            onMouseEnter={() => {
                notification.unread && markRead(notification.id);
            }}
        >
            <div className={classNames(classes.date, dateBlockClassName)}>
                <div>
                    <div>{formattedDate}</div>
                    <div>{formattedTime}</div>
                </div>
                <div className={classes.indicator} />
            </div>
            <div className={classNames(mainBlockClassName)}>
                <div className={classes.title}>{notification.title}</div>
                <Truncate
                    ref={truncateRef}
                    className={classNames(truncateClassName, classes.truncate)}
                    openedClassName={classes['truncate_open']}
                    dropShadowWhenClosed
                >
                    <div
                        id={cardContentId}
                        className={classNames(classes.content, 'mde-preview')}
                    >
                        <div
                            className={'mde-preview-content'}
                            dangerouslySetInnerHTML={{
                                __html: notification.content,
                            }}
                        />
                    </div>
                </Truncate>
            </div>
        </article>
    );
});
