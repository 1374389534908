import { FC } from 'react';
import { Footer as FooterComponent } from '@nkc-frontend/cat-design';
import { FooterProps } from '@nkc-frontend/cat-design/dist/components/Footer';
import { Link } from 'react-router-dom';

export const Footer: FC<FooterProps> = (props) => {
    return (
        <FooterComponent
            {...props}
            items={[
                <Link to={'/'} key={1}>
                    О KnowledgeCat
                </Link>,
                <Link to={'/'} key={1}>
                    Политика конфиденциальности
                </Link>,
                <Link to={'/'} key={1}>
                    Обратная связь
                </Link>,
                <Link to={'/'} key={1}>
                    Сообщить об ошибке
                </Link>,
            ]}
        />
    );
};
