import 'reflect-metadata';

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const app =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require(`./applications/${process.env.REACT_APP_BUILD}/entry`).default;
app();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
