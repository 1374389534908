import { useRootStore } from 'src/utils/mobx-store-utils/useRootStore';

/**
 * Хук, возвращающий сторы, лежащие в объекте RootStore
 */
export const useStores = <T extends Record<string, unknown>>(): T => {
    const rootStore = useRootStore<T>();

    if (!rootStore) {
        throw new Error(
            'Root Store must be set up in order to use useStores hook'
        );
    }

    return rootStore.getStores();
};
