import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { decorate, inject, injectable, optional } from 'inversify';
import {
    COMMON_DEP,
    GENERIC_CRUD_DEP,
} from 'src/applications/base/inversify/dependencyIdentifiers';
import {
    endRequestWithErrorHandler,
    startRequest,
} from 'src/applications/base/app/features/utils/rxutils/requestUtils';
import { IPendingRequestStore } from 'src/applications/base/app/features/pending-request-store/IPendingRequestStore';
import { ItemAndListCRUDStore } from 'src/applications/base/app/features/utils/crud/ItemAndListCRUD/ItemAndListCRUDStore';
import { saveItemToMap } from 'src/applications/base/app/features/utils/crud/ItemAndListCRUD/createSuccessHandlers';
import { IRequestErrorStore } from 'src/applications/base/app/features/request-error-store/IRequestErrorStore';
import { IAdminTabFilterStore } from 'src/applications/base/app/features/admin/admin-tabs/stores/IAdminTabFilterStore';
import { IAdminTabFilterService } from 'src/applications/base/app/features/admin/admin-tabs/services/IAdminTabFilterService';
import { action, makeObservable, observable } from 'mobx';

class _AdminTabFilterStore<
        TTabFilter,
        TFilterInfo,
        TTabFilterCreateFormFields,
        TTabFilterUpdateFormFields,
        TTabFilterListArg
    >
    extends ItemAndListCRUDStore<
        TTabFilter,
        TTabFilter,
        TTabFilterCreateFormFields,
        TTabFilterUpdateFormFields,
        TTabFilter,
        never,
        IAdminTabFilterService<
            TTabFilter,
            TFilterInfo,
            TTabFilterCreateFormFields,
            TTabFilterUpdateFormFields,
            TTabFilterListArg
        >
    >
    implements
        IAdminTabFilterStore<
            TTabFilter,
            TFilterInfo,
            TTabFilterCreateFormFields,
            TTabFilterUpdateFormFields,
            TTabFilterListArg
        >
{
    FETCH_FILTER_INFO_LIST_REQUEST_NAME = 'fetch_filter_info_list' as const;
    filterInfoList: TFilterInfo[] | null = null;

    fetchFilterInfoList$ = new Subject<string>();
    fetchFilterInfoListSub = this.fetchFilterInfoList$
        .pipe(
            startRequest(this, this.FETCH_FILTER_INFO_LIST_REQUEST_NAME),
            switchMap(() =>
                this.crudService
                    .fetchFilterInfoList()
                    .pipe(
                        endRequestWithErrorHandler(
                            this,
                            this.FETCH_FILTER_INFO_LIST_REQUEST_NAME
                        )
                    )
            )
        )
        .subscribe((filterInfoList) =>
            this.onFilterInfoListFetchSuccess(filterInfoList)
        );

    fetchFilterInfoList(): void {
        this.fetchFilterInfoList$.next();
    }

    getFilterInfoList(): TFilterInfo[] | null {
        return this.filterInfoList;
    }

    onFilterInfoListFetchSuccess(filterInfoList: TFilterInfo[]): void {
        this.filterInfoList = filterInfoList;
    }

    constructor(
        tabFilterService: IAdminTabFilterService<
            TTabFilter,
            TFilterInfo,
            TTabFilterCreateFormFields,
            TTabFilterUpdateFormFields,
            TTabFilterListArg
        >,
        pendingRequestStore: IPendingRequestStore,
        requestErrorStore: IRequestErrorStore,
        requestNamePrefix: string = 'ADMIN_TAB_FILTERS',
        onItemCreateSuccessHandler = saveItemToMap,
        uidField: string = 'id'
    ) {
        super(
            tabFilterService,
            pendingRequestStore,
            requestErrorStore,
            requestNamePrefix,
            onItemCreateSuccessHandler,
            uidField
        );
        makeObservable(this, {
            filterInfoList: observable,
            onFilterInfoListFetchSuccess: action,
        });
    }
}

export const AdminTabFilterStore = (() => {
    decorate(injectable(), _AdminTabFilterStore);
    decorate(
        inject(GENERIC_CRUD_DEP.GenericCRUDService) as any,
        _AdminTabFilterStore,
        0
    );
    decorate(
        inject(COMMON_DEP.PendingRequestStore) as any,
        _AdminTabFilterStore,
        1
    );
    decorate(
        inject(COMMON_DEP.RequestErrorStore) as any,
        _AdminTabFilterStore,
        2
    );
    decorate(
        inject(GENERIC_CRUD_DEP.RequestNamePrefix) as any,
        _AdminTabFilterStore,
        3
    );
    decorate(optional() as any, _AdminTabFilterStore, 3);
    decorate(
        inject(GENERIC_CRUD_DEP.OnCreateSuccessHandler) as any,
        _AdminTabFilterStore,
        4
    );
    decorate(optional() as any, _AdminTabFilterStore, 4);
    decorate(
        inject(GENERIC_CRUD_DEP.UidFieldName) as any,
        _AdminTabFilterStore,
        5
    );
    decorate(optional() as any, _AdminTabFilterStore, 5);
    return _AdminTabFilterStore;
})();
