/* ---------------------- СТОРЫ ------------------------- */

import { ICatalogsStore } from '../../app/features/catalog/stores/ICatalogsStore';
import { ICatalogsService } from '../../app/features/catalog/services/ICatalogsService';
import { ICatalogItemsStore } from '../../app/features/catalog-items-filter/stores/ICatalogItemsStore';
import { ICatalogItemsService } from '../../app/features/catalog-items-filter/services/ICatalogItemsService';
import { SelectFilterValuesContainer } from '../search/searchAPI';

export type CatalogsStoreType = ICatalogsStore<Catalog>;
export type CatalogsServiceType = ICatalogsService<Catalog[]>;

export type CatalogItemsStoreType = ICatalogItemsStore<
    CatalogItemFilterTree,
    CatalogItem
>;
export type CatalogItemsServiceType = ICatalogItemsService<
    CatalogItemFilterResponse,
    CatalogItem,
    CatalogItemDocCountResponse,
    CatalogItem[]
>;

/* ------------------------------- КАТАЛОГИ --------------------------*/

export enum CatalogItemType {
    Empty = 'Empty',
    Simple = 'Simple',
    Organization = 'Organization',
    Person = 'Person',
    Tag = 'Tag',
}

export const CatalogItemTypeDisplayName = {
    [CatalogItemType.Simple]: 'Справочник',
    [CatalogItemType.Organization]: 'Организация',
    [CatalogItemType.Person]: 'Физическое лицо',
    [CatalogItemType.Empty]: 'Пустой',
    [CatalogItemType.Tag]: 'Теги',
};

export interface Catalog {
    id: string;
    code: string;
    sourceId: string;
    title: string;
    description?: string;
    itemsType: CatalogItemType;
    priority?: number;
    archived: boolean;
    created: string;
    index: boolean;
    visibleToUser: boolean;
    inherit: boolean;
    updated?: string;
}

/* ------------------------------- КАТЕГОРИИ --------------------------*/

export interface CatalogItemListResponse {
    items: CatalogItem[];
    total: number;
}

export interface CatalogItemFilterResponse {
    items: SelectFilterValuesContainer;
    total: number;
}

export interface CatalogItemDocCountResponse {
    catalogItemId: string;
    docCount: number;
}

export interface CatalogItem {
    id: string;
    code: string;
    title: string;
    catalogId: string;
    description?: string;
    priority?: number;
    parent?: string;
    childrenCount?: number;
    taggingRulesCount?: number;
    keywords: string[];
    data: Record<string, unknown>;
    archived: boolean;
    created: string;
    updated?: string;
    md5sum: string;
}

export interface CatalogItemFilterValue {
    id: string;
    docCount: number;
    childrenCount?: number;
    taggingRulesCount?: number;
    parentCode?: string;
    type: string;
    title: string;
    children?: string[];
}

export interface CatalogItemFilterTree {
    items: Record<string, CatalogItemFilterValue>;
    top: string[];
    total: number;
}
