import classNames from 'classnames';
import { FC } from 'react';
import { Navigation } from 'src/applications/base/app/react-app/components/common/Navigation';
import { UserNavigation } from 'src/applications/base/app/react-app/components/common/UserNavigation';
import { BaseHeader } from 'src/applications/base/app/react-app/components/common/headers/BaseHeader';

import classes from './MainHeader.module.scss';

export interface MainHeaderProps {
    className?: string;
}

export const MainHeader: FC<MainHeaderProps> = (props) => {
    const { className } = props;

    return (
        <BaseHeader className={classNames(classes.header, className)}>
            <div className={classes.menus}>
                <Navigation className={classes.navigation} />
                <UserNavigation />
            </div>
        </BaseHeader>
    );
};
