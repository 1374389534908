import { IDocumentStore } from 'src/applications/base/app/features/documents/stores/IDocumentStore';
import { DocumentSearchHit } from 'src/applications/base/types/search/searchAPI';
import { IDocumentService } from 'src/applications/base/app/features/documents/services/IDocumentService';
import { AdminCatalogItemModel } from '../admin/adminCatalogItems';
import { NamespaceSchemaModel } from '../namespace-schema';
import { Pagination } from '../table';

export interface DocumentProperties {
    title?: string;
    abstract?: string[];
    accrualMethod?: string[];
    accrualPeriodicity?: string[];
    accrualPolicy?: string[];
    alternative?: string[];
    audience?: string[];
    // TODO: может тип другой будет
    backUrl?: string;
    bibliographicCitation?: string[];
    conformsTo?: string[];
    contributor?: string[];
    coverage?: string[];
    creator?: string[];
    description?: string[];
    educationLevel?: string[];
    extent?: string[];
    format?: string[];
    hasFormat?: string[];
    hasPart?: string[];
    hasVersion?: string[];
    identifier?: string[];
    instructionalMethod?: string[];
    language?: string[];
    license?: string[];
    mediator?: string[];
    medium?: string[];
    provenance?: string[];
    publisher?: string[];
    references?: string[];
    relation?: string[];
    replaces?: string[];
    requires?: string[];
    rights?: string[];
    rightsHolder?: string[];
    source?: string[];
    spatial?: string[];
    subject?: string[];
    tableOfContents?: string[];
    temporal?: string[];
    type?: string[];
    available?: string;
    created?: string;
    date?: string;
    dateAccepted?: string;
    dateCopyrighted?: string;
    dateSubmitted?: string;
    issued?: string;
    modified?: string;
    valid?: string;
}

export enum FilterFieldType {
    Tag = 'catalogs.tag',
    Source = 'documentSource',
    CreatedDate = 'properties.created',
}

export interface DocumentHighlights {
    'properties.abstract'?: string[];
    'properties.title'?: string[];
    content?: string[];
}

export type DocumentSnippet = DocumentSearchHit;
export const NerTitles: { [k in NerTypes]: string } = {
    ORG: 'Организации',
    product: 'Продукции',
    assignment: 'Поручения',
    supportLevel: 'Меры поддержки',
    LOC: 'Адреса',
    phoneNumber: 'Телефоны',
    PER: 'Персоны',
    officials: 'Должностные лица',
    npa: 'Упомянутые НПА',
};
export type NerAddress = 'LOC';
export type NerAssignment = 'assignment';
export type NerNpa = 'npa';
export type NerOfficials = 'officials';
export type NerOrganization = 'ORG';
export type NerPersonFullName = 'PER';
export type NerPhoneNumber = 'phoneNumber';
export type NerProduct = 'product';
export type NerSupportLevel = 'supportLevel';

export type NerTypes =
    | NerAddress
    | NerAssignment
    | NerNpa
    | NerOfficials
    | NerOrganization
    | NerPersonFullName
    | NerPhoneNumber
    | NerProduct
    | NerSupportLevel;

export type Ner<T extends NerTypes> = {
    hash: string;
    type: T;
};

export type NerValue<T extends NerTypes> = {
    value: string;
} & Ner<T>;

export type NerNpaValue = {
    documentDate: string;
    governmentAgency: string;
    number: string;
    kind: string;
} & Ner<'npa'>;

export type NerOfficial = {
    employeeTitle: string;
    personFullName: string;
} & Ner<'officials'>;
export type SomeNer =
    | NerValue<'LOC'>
    | NerValue<'assignment'>
    | NerNpaValue
    | NerOfficial
    | NerValue<'ORG'>
    | NerValue<'PER'>
    | NerValue<'phoneNumber'>
    | NerValue<'product'>
    | NerValue<'supportLevel'>;
export type NerFull = {
    LOC: NerValue<'LOC'>[];
    assignment: NerValue<'assignment'>[];
    npa: NerNpaValue[];
    officials: NerOfficial[];
    ORG: NerValue<'ORG'>[];
    PER: NerValue<'PER'>[];
    phoneNumber: NerValue<'phoneNumber'>[];
    product: NerValue<'product'>[];
    supportLevel: NerValue<'supportLevel'>[];
};

export interface CommonDocumentInfo {
    authors?: AdminCatalogItemModel[];
    organizations?: AdminCatalogItemModel[];
    objectTypes?: AdminCatalogItemModel[];
    sources?: AdminCatalogItemModel[];
    categories?: AdminCatalogItemModel[];
    tags?: AdminCatalogItemModel[];
    keywords?: string[];
    isFavorite: boolean;
    nersFull: null | NerFull;
}

export interface DocumentCard extends CommonDocumentInfo {
    id: string;
    properties?: DocumentProperties;
    downloadOriginalFile?: boolean;
    source?: {
        namespace: string;
        data: Record<string, unknown>;
    };
    highlights?: string[];
    content?: string;
    tags?: AdminCatalogItemModel[];
    isTags?: boolean;
}

export type DocumentStoreType = IDocumentStore<
    DocumentSnippet,
    DocumentCard,
    NamespaceSchemaModel
>;
export type DocumentServiceType = IDocumentService<
    DocumentCard,
    NamespaceSchemaModel
>;

export type SimilarityFiltersKeys = 'title';
export type SimilarityFilters = {
    [k in SimilarityFiltersKeys]: string[] | null;
};

export type NerFiltersKeys = "_title" | "_nerAll"

export type NerFilters = {
    [k in NerFiltersKeys]: string[] | null;
};

export interface SimilarityModel {
    id: string;
    created: string;
    title: string;
    score: number;
    type: string;
    _similarity: number;
}

export interface SimilarityFiltersAndPagination extends Pagination {
    filters: Partial<SimilarityFilters>;
}

export interface IIntersectionsList {
    score: number;
    intersections: string[];
    documentId: string;
}


export type NerModel = {
    id: string;
    score: number;
    source: DocumentCard & { indexedAt: string };
    ners: SomeNer[];
    _title: string;
    _date: string;
    _type: string;
    _nerAll: string
};

export type NerSearchResponse = {
    items: NerModel[];
    totalCount: number;
};

export type NerSearchParams = {
    id: string
    from: number
    limit: number
    title?: string
    ner?: string
}

export type NerIntersectionParam = {
    first: string,
    second: string,
}
