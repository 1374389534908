import { IItemAndListCRUDStore } from 'src/applications/base/app/features/utils/crud/ItemAndListCRUD/IItemAndListCRUDStore';
import get from 'lodash/get';

/**
 * Тип функции, которая будет вызывана в классом ItemAndListCRUDStore после успешного создания item
 */
export interface OnCreateSuccessHandler<
    TResponse,
    TFields,
    TCRUDListItem extends Record<string, any>
> {
    (
        crud: IItemAndListCRUDStore<TCRUDListItem, any, any, any, any, any>,
        response: TResponse,
        formFields: TFields,
        onSuccess?: (response: TResponse, formFields: TFields) => void
    ): void;
}

/**
 * Вызывает колбек onSuccess
 */
export const callCallback = <TResponse, TFields>(
    _: unknown,
    resp: TResponse,
    formFields: TFields,
    onSuccess?: (resp: TResponse, formFields: TFields) => void
) => {
    onSuccess?.(resp, formFields);
};

/**
 * Сохраняет item, пришедший в ответ на вызов crudService.create, в itemMap и вызывает колбек onSuccess
 */
export const saveItemToMap = <
    TResponse extends Record<string, any>,
    TFormFields
>(
    crud: IItemAndListCRUDStore<TResponse>,
    resp: TResponse,
    formFields: TFormFields,
    onSuccess?: (resp: TResponse, formFields: TFormFields) => void
) => {
    const itemMap = crud.getItemMap();
    if (resp && itemMap) {
        itemMap.set(get(resp, crud.getItemUidField()), resp);
    }
    onSuccess?.(resp, formFields);
};

/**
 * Загружает заново fetchItemList и вызывает колбек onSuccess
 */
export const fetchItems = (
    crud: IItemAndListCRUDStore<any>,
    resp: any,
    formFields: any,
    onSuccess?: (resp: any, formFields: any) => void
) => {
    crud.fetchItemList();
    onSuccess?.(resp, formFields);
};
