import { FC } from 'react';
import { DocumentSnippet as DocumentSnippetModel } from 'src/applications/base/types/document/documents';
import DocumentSnippetMarkup from 'src/applications/base/app/react-app/components/DocumentSnippet/DocumentSnippetMarkup';
import { useService } from '@redtea/react-inversify';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import {
    CatalogRoute,
    DocumentCardRoute,
} from 'src/applications/base/types/routing';
import { Link } from 'react-router-dom';
import { AdminCatalogItemModel } from 'src/applications/base/types/admin/adminCatalogItems';
import { observer } from 'mobx-react-lite';
import isBoolean from 'lodash/isBoolean';
import trim from 'lodash/trim';
import { DateFormatter } from '../../../utils/DateFormatter';
import { useStores } from '../../../../../../utils/mobx-store-utils/useStores';
import { SearchSlice } from '../../../../types/stores';

export interface DocumentSnippetProps {
    documentSnippet?: DocumentSnippetModel | null;
    className?: string;
    onLike?(liked: boolean): void;
}

const mapCatalogItem = (item?: AdminCatalogItemModel[]): string[] =>
    item?.map((item) => item.title) ?? [];

export const DocumentSnippet: FC<DocumentSnippetProps> = observer(
    ({ documentSnippet, className, onLike }) => {
        const { document: documentRouting, catalog: catalogRouting } =
            useService<DocumentCardRoute & CatalogRoute>(
                COMMON_DEP.ClientRouting
            );
        const source = documentSnippet?.source;
        const properties = source?.properties;
        const highlights = documentSnippet?.highlights;
        const { search: searchStore } = useStores<SearchSlice>();

        //TODO: вынести поиск и составление подсветки/контента в отдельный хук и потом использовать в карточке
        const title = properties?.title;
        const hContent =
            highlights?.['properties.abstract'] ?? highlights?.content;
        const abstract = properties?.abstract;
        const shortContent = source?.shortContent;
        const chunk = documentSnippet?.chunks?.[0]?.text?.[0] || '';
        const vectorBoostOverride = searchStore.getVectorBoostOverride();
        const annotation =
            (hContent || abstract)?.filter(Boolean).map(trim).join('. ') ||
            (vectorBoostOverride ? chunk || shortContent : shortContent);

        const formattedDate = DateFormatter.fromIsoString(
            properties?.created ?? ''
        ).toDocumentSnippet();

        const categories = source?.categories;
        const authors = mapCatalogItem(source?.authors?.slice(0, 2));
        const type = mapCatalogItem(source?.objectTypes).join(', ');
        const liked = source?.isFavorite;

        if (!documentSnippet) {
            return null;
        }

        return (
            <DocumentSnippetMarkup
                documentLink={documentRouting.getUrl({
                    id: documentSnippet.id,
                })}
                className={className}
                title={title}
                documentType={type}
                annotation={
                    <span
                        dangerouslySetInnerHTML={{
                            __html:
                                annotation /*|| chunk*/ ||
                                'Нет данных о содержимом',
                        }}
                    />
                }
                date={formattedDate}
                authors={authors.map((author, idx) => (
                    <span key={idx}>{author}</span>
                ))}
                categories={categories?.map((category, idx) => (
                    <Link
                        key={idx}
                        target={'_blank'}
                        to={catalogRouting.getUrl({
                            catalogId: category.catalogId,
                            itemId: category.id,
                        })}
                    >
                        {category.title}
                    </Link>
                ))}
                liked={liked}
                onLike={
                    isBoolean(documentSnippet.source?.isFavorite) && onLike
                        ? (liked) => onLike(liked)
                        : undefined
                }
            />
        );
    }
);
