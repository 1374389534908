import { useService } from '@redtea/react-inversify';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
    COMMON_DEP,
    FOOTER_DEP,
} from 'src/applications/base/inversify/dependencyIdentifiers';
import { IPageTitleProvider } from 'src/applications/base/app/features/page-title-provider/IPageTitleProvider';
import { RegularHeader } from '../../common/headers/RegularHeader';
import 'src/applications/base/stylesheets/admin/antd.scss';
import classes from './PageLayout.module.scss';
import { FooterComponent } from '../../../../../types/components';

export interface PageLayoutProps {
    className?: string;
}

export const PageLayout: FC<PageLayoutProps> = observer(
    ({ children, className }) => {
        const pageTitleProvider = useService<IPageTitleProvider>(
            COMMON_DEP.PageTitleProvider
        );
        const match = useRouteMatch();
        const Footer = useService<FooterComponent>(FOOTER_DEP.FooterComponent);
        return (
            <div className={classNames(classes['page-layout'], className)}>
                <RegularHeader
                    title={pageTitleProvider.getTitleByTemplate(match.path)}
                />
                <div className={classes.children}>{children}</div>
                <Footer className={classes.footer} mode='dark' />
            </div>
        );
    }
);
