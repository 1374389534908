import { FC } from 'react';
import { useLoadingBar } from 'src/applications/base/app/react-app/hooks/useLoadingBar';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { PendingRequestSlice } from 'src/applications/base/types/stores';
import { observer } from 'mobx-react-lite';

import classes from './LoadingBar.module.scss';

export const LoadingBar: FC = observer(() => {
    const { pendingRequest }: PendingRequestSlice = useStores();

    const loading = pendingRequest.pendingRequestCount > 0;

    const ref = useLoadingBar<HTMLDivElement>({ loading });

    return (
        <div className={classes.wrapper}>
            <div ref={ref} className={classes.bar} />
        </div>
    );
});
