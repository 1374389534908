import { SelectOption } from '@nkc-frontend/cat-design/dist/components/List';
import { InlineSelect, SidepageSelect } from '@nkc-frontend/cat-design';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import {
    ActiveFilterValue,
    DisplayFilterValues,
    FilterInfoRecord,
    FilterValue,
    HierarchicalFilterValues,
    isDisplaySelectFilterValue,
    isHierarchicalFilterValues,
    isSelectFilterValues,
    isYearRangeFilterValues,
    RangeFilterActiveValue,
    RangeFilterValues,
    SelectFilterValues,
} from 'src/applications/base/types/search/searchStores';
import { IActiveFilterStore } from 'src/applications/base/app/features/search/stores/SearchFiltersStore/IActiveFilterStore';
import { useComputed } from 'src/applications/base/app/react-app/hooks/useComputed';
import { toJS } from 'mobx';
import { TimePeriodSelectLazy } from '../TimePeriodSelectLazy/TimePeriodSelectLazy';

interface SettingsProviderStore {
    activeFilterStore: IActiveFilterStore<ActiveFilterValue>;

    getCurrentFilterValues(filterId: string): FilterValue | null;
}

export interface FilterProps {
    filterInfo: FilterInfoRecord;
    settingsStore: SettingsProviderStore;

    onFilterChange(): void;

    /**
     * функция нужна только для InlineSelect/SideboxSelect фильтров
     */
    valueToOptions?(value: FilterValue): SelectOption[];
}

const defaultValueToOptions = (
    values: SelectFilterValues | HierarchicalFilterValues | DisplayFilterValues
): SelectOption[] => {
    if (isSelectFilterValues(values) || isDisplaySelectFilterValue(values)) {
        return values.topValueList.map((id) => ({
            value: values.values[id].value,
            label: values.values[id].value,
            count: values.values[id].docCount,
        }));
    } else {
        return values.topValueList.map(function id2Opt(code): SelectOption {
            const val = values.values[code];
            return {
                value: val.code,
                label: val.value,
                options:
                    val.childrenCodes.length > 0
                        ? val.childrenCodes.map(id2Opt)
                        : undefined,
                count: val.docCount,
            };
        });
    }
};

export const Filter: FC<FilterProps> = observer((props) => {
    const {
        filterInfo,
        settingsStore,
        valueToOptions = defaultValueToOptions,
        children,
        onFilterChange,
    } = props;

    const options = useComputed(() => {
        const values = settingsStore.getCurrentFilterValues(filterInfo.id);
        if (
            values &&
            (isSelectFilterValues(values) ||
                isHierarchicalFilterValues(values) ||
                isDisplaySelectFilterValue(values))
        ) {
            return toJS(valueToOptions(values));
        }
        return [];
    }, [filterInfo, valueToOptions, settingsStore]);

    const active = useComputed(() => {
        return (
            toJS(
                settingsStore.activeFilterStore.getActiveFilterValues(
                    filterInfo.id
                )
            ) ?? []
        );
    }, [settingsStore, filterInfo]);

    const onSelect = useCallback(
        (opt: SelectOption) => {
            settingsStore.activeFilterStore.addValue(filterInfo.id, opt.value);
            onFilterChange();
        },
        [settingsStore, filterInfo, onFilterChange]
    );

    const onDeselect = useCallback(
        (opt: SelectOption) => {
            settingsStore.activeFilterStore.removeValue(
                filterInfo.id,
                opt.value
            );
            onFilterChange();
        },
        [settingsStore, filterInfo, onFilterChange]
    );

    const onApply = useCallback(
        (opt: string[]) => {
            settingsStore.activeFilterStore.setActive(filterInfo.id, opt);
            onFilterChange();
        },
        [settingsStore, filterInfo, onFilterChange]
    );

    const onFromChange = useCallback(
        (from?: number) => {
            settingsStore.activeFilterStore.mergeValue(filterInfo.id, {
                gt: from,
            });
            onFilterChange();
        },
        [settingsStore, filterInfo, onFilterChange]
    );
    const onToChange = useCallback(
        (to?: number) => {
            settingsStore.activeFilterStore.mergeValue(filterInfo.id, {
                lt: to,
            });
            onFilterChange();
        },
        [settingsStore, filterInfo, onFilterChange]
    );
    const onPeriodChange = useCallback(
        (from?: number, to?: number) => {
            settingsStore.activeFilterStore.setActive(filterInfo.id, {
                gt: from,
                lt: to,
            });
            onFilterChange();
        },
        [settingsStore, filterInfo, onFilterChange]
    );

    let filter = null;
    const filterValues = settingsStore.getCurrentFilterValues(filterInfo.id);

    if (
        filterValues &&
        (isSelectFilterValues(filterValues) ||
            isDisplaySelectFilterValue(filterValues))
    ) {
        filter = (
            <InlineSelect
                title={filterInfo.name}
                options={options}
                active={active as string[]}
                onSelect={onSelect}
                onDeselect={onDeselect}
            />
        );
    } else if (filterValues && isHierarchicalFilterValues(filterValues)) {
        filter = (
            <SidepageSelect
                title={filterInfo.code}
                options={options}
                active={active as string[]}
                onApply={onApply}
            />
        );
    } else if (filterValues && isYearRangeFilterValues(filterValues)) {
        const values = filterValues as RangeFilterValues | undefined;
        if (!values) {
            return null;
        }

        const activeValue =
            settingsStore.activeFilterStore.getActiveFilterValues(
                filterInfo.id
            ) as RangeFilterActiveValue | undefined;

        filter = (
            <TimePeriodSelectLazy
                title={'Период времени'}
                from={activeValue?.gt}
                to={activeValue?.lt}
                onFromChange={onFromChange}
                onToChange={onToChange}
                onPeriodChange={onPeriodChange}
            />
        );
    }

    if (typeof children === 'function') {
        return children(filter);
    } else {
        return filter;
    }
});
