import React, { ReactElement, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from '@nkc-frontend/cat-design';
import { Table } from '../Table';
import { FontAwesomeIcons } from 'src/applications/base/app/utils/constants';
import { TableProps } from 'antd/lib/table';

import classes from './FilterTable.module.scss';

export interface IFilterTableProps<T> extends TableProps<T> {
    onResetFiltersClick?: () => void;
}

export function FilterTable<T>(props: IFilterTableProps<T>): ReactElement {
    const { className, onResetFiltersClick, columns, ...tableProps } = props;

    const showClearFiltersButton = useMemo(() => {
        return (
            columns?.some((column) => {
                const filters = column.filteredValue;

                return Array.isArray(filters) && filters.length > 0;
            }) ?? false
        );
    }, [columns]);

    return (
        <div className={classes['filter-table']}>
            <Button
                size='S'
                visualStyle='plain-link'
                className={classNames(classes['clear-filters-button'], {
                    [classes['clear-filters-button_show']]:
                        showClearFiltersButton,
                })}
                onClick={onResetFiltersClick}
                before={<i className={FontAwesomeIcons.TRASH} />}
            >
                Очистить фильтры
            </Button>
            <Table
                {...tableProps}
                columns={columns}
                className={classNames(className)}
                showSorterTooltip={false}
            />
        </div>
    );
}
