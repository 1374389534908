import { FC, useMemo } from 'react';
import {
    Button,
    Dropdown,
    Icon,
    Menu,
    MenuItem,
} from '@nkc-frontend/cat-design';
import { useOnOutsideClick } from '@nkc-frontend/nkc-react-hooks';

import classes from './ListSorting.module.scss';

export interface SortOption {
    label: string;
    code: string | null;
    order: 'ASC' | 'DESC' | null;
}

export interface ListSortingProps {
    activeCode: string | null;
    activeOrder: string | null;
    options: SortOption[];
    listOpened?: boolean;
    onChange(option: SortOption): void;
    onLabelClick?(): void;
    onMenuOutsideClick?(): void;
}

export const ListSorting: FC<ListSortingProps> = (props) => {
    const {
        activeCode,
        activeOrder,
        options,
        onChange,
        listOpened,
        onLabelClick,
        onMenuOutsideClick,
    } = props;

    const activeOption = useMemo(() => {
        return options.find(
            (opt) => opt.code === activeCode && opt.order === activeOrder
        );
    }, [activeCode, activeOrder, options]);

    const onMenuMouseDown = useOnOutsideClick(() => onMenuOutsideClick?.());

    return (
        <div>
            <Dropdown
                popupVisible={listOpened}
                popup={
                    <Menu onMouseDown={onMenuMouseDown}>
                        {options.map((opt) => (
                            <MenuItem key={`${opt.code}_${opt.order}`}>
                                <Button
                                    visualStyle={'plain'}
                                    onClick={() => onChange(opt)}
                                >
                                    {opt.label}
                                </Button>
                            </MenuItem>
                        ))}
                    </Menu>
                }
                popupAlign={{
                    points: ['tr', 'br'],
                }}
            >
                <Button
                    visualStyle={'plain'}
                    onClick={onLabelClick}
                    className={classes['label-btn']}
                    after={
                        <Icon
                            icon={'sorting'}
                            size={'S'}
                            className={classes.icon}
                        />
                    }
                    size={'S'}
                >
                    {activeOption?.label ?? ''}
                </Button>
            </Dropdown>
        </div>
    );
};
