import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useService } from '@redtea/react-inversify';
import {
    COMMON_DEP,
    LOGO_DEP,
} from 'src/applications/base/inversify/dependencyIdentifiers';
import { MainRoute } from 'src/applications/base/types/routing';
import { PageTitle } from 'src/applications/base/app/react-app/components/common/PageTitle';
import { Navigation } from 'src/applications/base/app/react-app/components/common/Navigation';
import { UserNavigation } from 'src/applications/base/app/react-app/components/common/UserNavigation';
import { BaseHeader } from 'src/applications/base/app/react-app/components/common/headers/BaseHeader';

import classes from './RegularHeader.module.scss';
import { HeaderLogoComponent } from 'src/applications/base/types/components';

export interface RegularHeaderProps {
    className?: string;
    title?: string;
}

export const RegularHeader: FC<RegularHeaderProps> = (props) => {
    const { className, title } = props;

    const { main } = useService<MainRoute>(COMMON_DEP.ClientRouting);
    const Logo = useService<HeaderLogoComponent>(LOGO_DEP.HeaderLogoComponent);

    return (
        <BaseHeader className={classNames(classes.header, className)}>
            <div className={classes['logo-wrapper']}>
                <Link to={main.getUrl()}>
                    <Logo className={classes.logo} />
                </Link>
            </div>
            {title && (
                <div>
                    <PageTitle>{title}</PageTitle>
                </div>
            )}
            <div className={classes.menus}>
                <Navigation className={classes.navigation} />
                <UserNavigation />
            </div>
        </BaseHeader>
    );
};
