import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { SelectOption } from '@nkc-frontend/cat-design/dist/components/List/SelectOptions';
import { Button, Icon } from '@nkc-frontend/cat-design';
import {
	ActiveFilterValue, ActiveTabStoreType,
	FilterInfoRecord,
	FilterValue,
} from 'src/applications/base/types/search/searchStores';
import {
	IActiveFilterStore,
} from 'src/applications/base/app/features/search/stores/SearchFiltersStore/IActiveFilterStore';
import { Filter } from 'src/applications/base/app/react-app/components/common/FilterPanel/Filter';

import classes from './FilterPanel.module.scss';
import { useComputed } from 'src/applications/base/app/react-app/hooks/useComputed';
import { useStores } from '../../../../../../../../utils/mobx-store-utils/useStores';
import { PendingRequestSlice } from '../../../../../../types/stores';

export interface SearchSettingsStore {
	activeFilterStore: IActiveFilterStore<ActiveFilterValue>;
	activeTabStore?: ActiveTabStoreType,
	
	getCurrentFilterList(): FilterInfoRecord[] | null;
	
	getCurrentFilterValues(filterId: string): FilterValue | null;
	
	activeFiltersToDefaults(): void;
}

export interface FilterPanelSearchStore {
	load(): void;
	
	loadDocuments(): void;
	
	exportReport(): void;
	
	settings: SearchSettingsStore;
}

export interface FilterPanelProps {
	search?: FilterPanelSearchStore;
	
	filterValueToOptions?(value: FilterValue): SelectOption[];
	
	renderFilter?(
		filter: FilterInfoRecord,
		props: FilterPanelProps,
		defaultWrapFunc: (filterNode: ReactNode) => ReactNode,
	): ReactNode;
}

const defaultRenderFilter = (
	filter: FilterInfoRecord,
	props: FilterPanelProps,
	defaultWrapFunc: (filterNode: ReactNode) => ReactNode,
): ReactNode =>
	props.search ? (
		<Filter
			key={filter.id}
			filterInfo={filter}
			settingsStore={props.search.settings}
			valueToOptions={props.filterValueToOptions}
			onFilterChange={() => {
				const activeTab = props.search?.settings.activeTabStore?.getActiveTab();
				activeTab?.code !== 'favorites'
					? props.search?.load()
					: props.search?.loadDocuments();
			}}
		>
			{defaultWrapFunc}
		</Filter>
	) : null;

const defaultWrapFunc = (filterElement: ReactNode) =>
	filterElement ? (
		<div className={classes.filter}>{filterElement}</div>
	) : null;

export const FilterPanel: FC<FilterPanelProps> = observer((props) => {
	const { renderFilter = defaultRenderFilter, search } = props;
	
	const filterList = search?.settings.getCurrentFilterList() ?? [];
	
	const visibleOrderedFilters = useComputed(
		() =>
			filterList
				.filter((filter) => !filter.settings.hidden)
				.sort(
					(filterA, filterB) =>
						filterA.settings.sort - filterB.settings.sort,
				),
		[filterList],
	);
	
	if (!search) {
		return null;
	}
	const { pendingRequest } = useStores<PendingRequestSlice>();
	const isFavorite = useComputed(() => search?.settings.activeTabStore?.getActiveTab()?.code === 'favorites', [search]);
	const onToDefaults = () => {
		search.settings.activeFiltersToDefaults();
		!isFavorite
			? search?.load()
			: search?.loadDocuments();
	};
	
	return (
		<div>
			<div className={classes.header}>
				<div className={classes.header__button}>
					<div className={classes.title}>Фильтры</div>
					{
						!isFavorite && (
							<Button
								className={classes['clear-btn']}
								before={<Icon icon={'download'} size={'S'}/>}
								visualStyle={'plain-link'}
								disabled={pendingRequest.isPending(
									'EXPORT_STATISTICS',
								)}
								onClick={() => {
									search.exportReport();
								}}
								title={'Скачать отчет со статистикой'}
							/>
						)
					}
				
				</div>
				
				{!search.settings.activeFilterStore.isDefault() && (
					<Button
						className={classes['clear-btn']}
						before={<Icon icon={'close'} size={'XS'}/>}
						visualStyle={'plain-link'}
						onClick={onToDefaults}
					>
						Сбросить
					</Button>
				)}
			</div>
			{visibleOrderedFilters.map((filter) =>
				renderFilter(filter, props, defaultWrapFunc),
			)}
		</div>
	);
});
