import { AsyncContainerModule } from 'inversify';
import React, { useEffect, useState } from 'react';
import { useContainer } from '@redtea/react-inversify';
import { useRootStore } from 'src/utils/mobx-store-utils/useRootStore';

/**
 * Возвращает HOC, который загрузит modules и только после этого рендерит обёрнутый компонент. Так же добавит в RootStore
 * указанные в stores сторы
 * @param modules - список асинхронных модулей, которые будут загружены
 * @param stores - Мапа, в которой ключи - название store, который будет добавлен в RootStore, а значение - symbol,
 *      по которому зависимость будет получена из di контейнера
 */
export const createWithAsyncModules = <TStores extends Record<string, any>>(
    modules: AsyncContainerModule[],
    stores?: Partial<Record<keyof TStores, symbol>>
) => {
    let modulesLoaded = false;

    return <TProps extends Record<string, any>>(
            WrappedComponent: React.ComponentType<TProps>
        ): React.FC<TProps> =>
        (props: TProps) => {
            const [areModulesLoaded, setAreModulesLoaded] =
                useState(modulesLoaded);

            const container = useContainer();
            const rootStore = useRootStore();

            useEffect(() => {
                if (areModulesLoaded) {
                    return;
                }
                container
                    .loadAsync(...modules)
                    .then(() => {
                        if (stores) {
                            Object.entries(stores).forEach(
                                ([storeName, storeId]) => {
                                    if (!storeId) {
                                        console.error(
                                            `Dependency ID for ${storeName} should be defined`
                                        );
                                        return;
                                    }
                                    try {
                                        const store = container.get(storeId);
                                        rootStore.register(storeName, store);
                                    } catch (e) {
                                        console.error(
                                            `Couldn't resolve store ${storeId.toString()} from DI container:`
                                        );
                                        console.error(e);
                                    }
                                }
                            );
                        }
                        modulesLoaded = true;
                        setAreModulesLoaded(true);
                    })
                    .catch((err) => {
                        console.error(
                            'Error during async container load module:'
                        );
                        console.error(err);
                    });
            }, [areModulesLoaded, container, rootStore]);

            return areModulesLoaded ? <WrappedComponent {...props} /> : null;
        };
};
