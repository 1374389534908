import React, { FC} from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import { DocumentSlice } from '../../../../../types/stores';
import { useComputed } from '../../../hooks/useComputed';
import classes from '../SimilaritySidebar/SimilaritySidebar.module.scss';
import { NerTitles, NerTypes } from '../../../../../types/document/documents';
import { generateNerText } from '../common';


export const NerSideBar: FC = observer(
	() => {
		const { documents: documentStore } = useStores<DocumentSlice>();
		const selectedDocumentNers = useComputed(
			() => {
				const someNers = documentStore.selectedDocumentNers;
				const data: {
					[k in NerTypes]: {
					title: string,
					list: string[],
				} | null
				} = {
					ORG: null,
					product: null,
					assignment: null,
					supportLevel: null,
					LOC: null,
					phoneNumber: null,
					PER: null,
					officials: null,
					npa: null,
				};
				
				someNers.forEach(someNer => {
					const type = someNer.type;
					const text = generateNerText(someNer)
					if(!data[type]){
						data[type] = {
							title: NerTitles[type],
							list: [text]
						}
					}else {
						data[type]?.list.push(text)
					}
				})
				
				return data
			},
			[documentStore]
		);
		
		return (
			<>
				<div className={classes.container}>
					<button
						type='button'
						className='cd-button'
						onClick={() => documentStore.closeSideBar()}
					>
						<span className='cd-icon cd-icon_size_m cd-icons-close'></span>
					</button>
					<div className={classes.title}>Пересекающиеся данные</div>
					
					<div className={classes.list}>
						{Object.values(selectedDocumentNers).map((ner, i) => ner?.list.length && (
							<div key={i} className={classes.ner}>
								<div className={classes.ner__title}>{ner?.title}</div>
								<div className={classes.ner__text}>{ner?.list.map((text, j)=> (
									<div key={`${i}_${j}`}>{text}</div>
								))}</div>
							</div>
						))}
					</div>
				
				</div>
			</>
		);
	}
);
