import { Link } from 'react-router-dom';
import { LikeButton } from '../common/LikeButton';

import classes from 'src/applications/base/app/react-app/components/DocumentSnippet/DocumentSnippetMarkup.module.scss';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export interface DocumentSnippetMarkupProps {
    documentLink: string;
    className?: string;
    title?: string;
    annotation?: string | ReactNode;
    categories?: ReactNode[];
    source?: ReactNode;
    authors?: ReactNode[];
    date?: string;
    liked?: boolean;
    documentType?: string;
    imgSrc?: string;
    imgSrcSet?: string;
    imgAlt?: string;
    imgTitle?: string;

    onLike?(liked: boolean): void;
    onClick?(): void;
}

const DocumentSnippetMarkup: FC<DocumentSnippetMarkupProps> = ({
    documentLink,
    className,
    title,
    annotation,
    categories,
    source,
    authors,
    date,
    liked,
    documentType,
    imgSrc,
    imgSrcSet,
    imgAlt,
    imgTitle,

    onLike,
    onClick,
}) => {
    return (
        <article
            className={classNames(
                classes['document-snippet'],
                classes['document-snippet_with_image'],
                className
            )}
            data-testid={'document-snippet'}
            onClick={onClick}
        >
            <LikeButton
                type='icon'
                className={classes['document-snippet__like-button']}
                liked={liked}
                onLike={onLike}
            />
            <section className={classes['document-snippet__upper-info-line']}>
                <div>{date}</div>
                <div>{documentType}</div>
                {source && <div>Источник: {source}</div>}
                {Array.isArray(authors) && authors.length > 0 && (
                    <div className={classes['document-snippet__authors']}>
                        {authors.reduce((prev, curr) =>
                            [prev, ', ', curr].flat()
                        )}
                    </div>
                )}
            </section>
            <Link to={documentLink}>
                <h2 className={classes['document-snippet__title']}>{title}</h2>
            </Link>
            <section className={classes['document-snippet__main']}>
                {imgSrc && (
                    <img
                        className={classes['document-snippet__image']}
                        src={imgSrc}
                        srcSet={imgSrcSet}
                        alt={imgAlt}
                        title={imgTitle}
                    />
                )}
                <div>
                    <p className={classes['document-snippet__annotation']}>
                        {annotation}
                    </p>
                    {Array.isArray(categories) && categories.length > 0 && (
                        <div
                            className={classes['document-snippet__categories']}
                        >
                            Категории:{' '}
                            {categories.reduce((prev, curr) =>
                                [prev, ', ', curr].flat()
                            )}
                        </div>
                    )}
                </div>
            </section>
        </article>
    );
};

export default DocumentSnippetMarkup;
