import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { useLatest, useUnmount } from 'react-use';
import { RouteComponentProps } from 'react-router-dom';
import { DocumentSlice, SearchSlice } from 'src/applications/base/types/stores';
import { MainSearchSummary } from '../MainSearchSummary';

import { SearchResult } from 'src/applications/base/app/react-app/components/common/search-result/SearchResult';
import { SearchParams, SearchRoute } from 'src/applications/base/types/routing';
import { useService } from '@redtea/react-inversify';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';

export type SearchProps = RouteComponentProps<SearchParams>;

export const Search: FC<SearchProps | any> = observer(({ match, history }) => {
    const { search, documents } = useStores<SearchSlice & DocumentSlice>();
    const { search: searchRoute } = useService<SearchRoute>(
        COMMON_DEP.ClientRouting
    );

    const searchAliasFromUrl = match.params.alias;
    const searchAliasFromUrlRef = useLatest(searchAliasFromUrl);
    const lastSuccessfulSearchAlias = search.getLastSuccessfulAlias();
    const lastSuccessfulSearchAliasRef = useLatest(lastSuccessfulSearchAlias);

    useUnmount(() => search.clearUp());

    useEffect(() => {
        search.settings.fetchSearchSettings();
    }, [search]);

    useEffect(() => {
        if (lastSuccessfulSearchAliasRef.current !== searchAliasFromUrl) {
            search.loadFromAlias(searchAliasFromUrl);
        }
    }, [searchAliasFromUrl, search, lastSuccessfulSearchAliasRef]);

    useEffect(() => {
        if (
            lastSuccessfulSearchAlias &&
            lastSuccessfulSearchAlias !== searchAliasFromUrlRef.current
        ) {
            history.push(
                searchRoute.getUrl({ alias: lastSuccessfulSearchAlias })
            );
        }
    }, [
        lastSuccessfulSearchAlias,
        searchAliasFromUrlRef,
        searchRoute,
        history,
    ]);

    return (
        <SearchResult
            search={search}
            documents={documents}
            topNode={<MainSearchSummary />}
        />
    );
});
