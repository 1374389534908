import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { DocumentCard } from 'src/applications/base/types/document/documents';
import { DocumentSlice } from 'src/applications/base/types/stores';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { useComputed } from '../../../hooks/useComputed';
import { DocumentDataTreeLazy } from '../../../components/DocumentDataTree/DocumentDataTreeLazy';
import { toJS } from 'mobx';
import classNames from 'classnames';

import getDocumentTreeData from './getDocumentTreeData';

import classes from './DocumentAdditionalProperties.module.scss';
import { formatDatesInObject } from '../../../../utils/formatDatesInObject';

interface DocumentAdditionalPropertiesProps {
    document: DocumentCard;
    className?: string;
}

export const DocumentAdditionalProperties: React.FC<DocumentAdditionalPropertiesProps> =
    observer(({ document, className }) => {
        const { documents } = useStores<DocumentSlice>();

        const sourceData = document.source?.data;
        const namespace = document.source?.namespace;

        useEffect(() => {
            namespace && documents.fetchSchema(namespace);
        }, [namespace, documents]);

        const schema = useComputed(
            () => namespace && toJS(documents.getSchema(namespace)),
            [namespace]
        );

        const treeData = useMemo(
            () =>
                sourceData && schema
                    ? getDocumentTreeData(schema.properties, formatDatesInObject(sourceData))
                    : undefined,
            [schema, sourceData]
        );

        return sourceData && schema ? (
            
            <section
                className={classNames(
                    classes['document-additional-properties'],
                    className
                )}
            >
                <DocumentDataTreeLazy
                    data-testid='document-data-tree'
                    defaultExpandAll
                    treeData={[
                        {
                            key: 'root',
                            title: (
                                <span className={classes['property-name']}>
                                    Дополнительные данные документа
                                </span>
                            ),
                            children: treeData,
                        },
                    ]}
                />
            </section>
        ) : null;
    });
