import { FC } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { SearchHeader } from 'src/applications/base/app/react-app/components/common/headers/SearchHeader';
import classes from './SearchLayout.module.scss';
import { SpellCheck } from '../../common/search-result/SpellCheck/SpellCheck';
import {useService} from "@redtea/react-inversify";
import {FooterComponent} from "../../../../../types/components";
import {FOOTER_DEP} from "../../../../../inversify/dependencyIdentifiers";

export interface SearchLayoutProps {
    className?: string;
}

export const SearchLayout: FC<SearchLayoutProps> = observer(
    ({ className, children }) => {
        const Footer = useService<FooterComponent>(FOOTER_DEP.FooterComponent)
        return (
            <div className={classNames(classes['search-layout'], className)}>
                <SearchHeader
                    logoAndSearchBarClassName={classes['header-logo-searchbar']}
                    searchBarContainerClassName={
                        classes['search-bar-container']
                    }
                ></SearchHeader>
                <SpellCheck />
                <div className={classes.children}>{children}</div>
                <Footer mode={'dark'} className={classes.footer} />
            </div>
        );
    }
);
