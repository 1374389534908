import { TableProps } from 'antd/lib/table';
import { useState, useCallback } from 'react';
import { TableFilters } from 'src/applications/base/types/table';

interface ITableFiltersStateAndCallbacks<TableData> {
    /**
     * состояние фильтров
     */
    filters: Partial<TableFilters<TableData>>;
    /**
     * коллбэк для передачи в `FilterTable->onChange`
     */
    handleTableChange: NonNullable<TableProps<TableData>['onChange']>;
    /**
     * коллбэк для передачи в `FilterTable->onResetFiltersClick`
     */
    resetFilters: () => void;
}

/**
 * Используется вместе с компонентом FilterTable
 * для управления состоянием фильтров колонок таблицы
 * @param emptyFilters
 */
export const useFilterTableFilters = <TableData>(
    emptyFilters: Partial<TableFilters<TableData>>
): ITableFiltersStateAndCallbacks<TableData> => {
    const [filters, setFilters] = useState(emptyFilters);

    const handleTableChange = useCallback<
        ITableFiltersStateAndCallbacks<TableData>['handleTableChange']
    >((_, filters) => {
        setFilters(filters as Partial<TableFilters<TableData>>);
    }, []);

    const resetFilters = useCallback(() => {
        setFilters(emptyFilters);
    }, [emptyFilters]);

    return {
        filters,
        handleTableChange,
        resetFilters,
    };
};
