import { FC } from 'react';

import classNames from 'classnames';
import {
    Avatar,
    Button,
    Dropdown,
    Icon,
    LinkButton,
    Menu,
    MenuDivider,
    MenuItem,
} from '@nkc-frontend/cat-design';
import { Badge } from 'antd';

import classes from './UserNavigation.module.scss';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import {
    AuthorizationSlice,
    NotificationSlice,
    ProfileSlice,
} from 'src/applications/base/types/stores';
import { observer } from 'mobx-react-lite';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { useService } from '@redtea/react-inversify';
import { ProfileRoute } from 'src/applications/base/types/routing';

export interface UserNavigationProps {
    className?: string;
}

export const UserNavigation: FC<UserNavigationProps> = observer(
    ({ className }) => {
        const { profile: profileRouting } = useService<ProfileRoute>(
            COMMON_DEP.ClientRouting
        );
        const { notifications, profiles, authorization } = useStores<
            NotificationSlice & ProfileSlice & AuthorizationSlice
        >();
        const notificationCount = notifications.getUnreadCount();
        const overflowCount = 9;

        const profileInitials = profiles.getNameInitials();

        return (
            <Dropdown
                popupClassName={classes['dropdown']}
                popup={(toggle) => (
                    <Menu>
                        <MenuItem>
                            <span className={classes['menu-header-name']}>
                                {profiles.getNameAndSurname()}
                            </span>
                            <span className={classes['menu-header-email']}>
                                {profiles.getProfile()?.email}
                            </span>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem>
                            <LinkButton
                                visualStyle={'plain'}
                                to={profileRouting.getUrl()}
                                before={<Icon size={'M'} icon='profile' />}
                                onClick={() => toggle()}
                            >
                                Профиль
                            </LinkButton>
                        </MenuItem>
                        <MenuItem>
                            <LinkButton
                                visualStyle={'plain'}
                                to={profileRouting.subRoutes.favorite.getUrl()}
                                before={
                                    <Icon size={'M'} icon='favorite-stroke' />
                                }
                                onClick={() => toggle()}
                            >
                                Избранное
                            </LinkButton>
                        </MenuItem>
                        <MenuItem>
                            <LinkButton
                                className={classes['notifications']}
                                visualStyle={'plain'}
                                to={profileRouting.subRoutes.notifications.getUrl()}
                                before={<Icon size={'M'} icon='notification' />}
                                onClick={() => toggle()}
                            >
                                <span className={classes['notification-title']}>
                                    Уведомления&nbsp;
                                </span>
                                <Badge
                                    className={classes['notification-badge']}
                                    count={notificationCount}
                                    overflowCount={overflowCount}
                                />
                            </LinkButton>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem>
                            <Button
                                className={classes['logout']}
                                visualStyle={'plain'}
                                onClick={authorization.logout}
                                before={<Icon size={'M'} icon={'exit'} />}
                            >
                                Выйти
                            </Button>
                        </MenuItem>
                    </Menu>
                )}
                popupAlign={{
                    points: ['tr', 'br'],
                }}
            >
                <span className={classNames(className, classes.profile)}>
                    <Badge
                        className={classes['notification-badge']}
                        count={notificationCount}
                        overflowCount={overflowCount}
                        size={'default'}
                    >
                        <Avatar alt={profileInitials} />
                    </Badge>
                </span>
            </Dropdown>
        );
    }
);
