import { DateFormatter } from 'src/applications/base/app/utils/DateFormatter';
import { DocumentCard } from 'src/applications/base/types/document/documents';
import { joinCatalogItemsTitles } from '../common';
import { DocumentPropertiesView } from '../DocumentPropertiesView';

interface DocumentAsidePropertiesProps {
    document: DocumentCard;
}

export const DocumentAsideProperties: React.FC<DocumentAsidePropertiesProps> =
    ({ document }) => {
        const objectTypes = joinCatalogItemsTitles(document?.objectTypes);
        const authors = joinCatalogItemsTitles(document?.authors);
        const categories = joinCatalogItemsTitles(document?.categories);
        const tags = document?.tags?.filter((tag) => !tag.archived);

        const formattedCreatedDate = DateFormatter.fromIsoString(
            document?.properties?.created ?? ''
        ).toDocumentCard();

        return (
            <DocumentPropertiesView
                properties={[
                    {
                        name: 'created',
                        title: 'Дата создания',
                        content: formattedCreatedDate,
                    },
                    {
                        name: 'type',
                        title: 'Тип документа',
                        content: objectTypes,
                    },
                    {
                        name: 'authors',
                        title: 'Авторы',
                        content: authors,
                    },
                    {
                        name: 'categories',
                        title: 'Категории',
                        content: categories,
                    },
                    {
                        name: 'tags',
                        title: 'Тэги',
                        tags: tags,
                        isTags: true,
                        truncate: 7,
                    },
                ]}
            />
        );
    };
