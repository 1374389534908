import { TableFilters } from '../../../../../types/table';
import {
	NerFilters,
	NerModel,
} from '../../../../../types/document/documents';
import ClientRoute from '../../../../features/utils/client-routing/ClientRoute/ClientRoute';
import { ColumnProps } from 'antd/es/table';
import {
	getAutocompleteFilterColumnProps,
	getSearchFilterColumnProps,
} from '../../../../utils/tableColumns';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { DATE_FORMAT } from '../../../../utils/constants';
import React from 'react';
import {
	ReactComponent as IdentitySources,
} from 'src/applications/base/images/navigation/identity-sources.svg';

export const getDocumentsColumns = (
	filters: Partial<TableFilters<NerFilters>>,
	documentRouting: ClientRoute,
	onCellClick: (id: string) => void,
	options: any[],
): ColumnProps<NerModel>[] => [
	{
		title: 'Документ',
		key: '_title',
		dataIndex: '_title',
		...getSearchFilterColumnProps('Название документа'),
		filteredValue: filters._title,
		width: '30%',
		render: (_title: string, { id }) => (
			<Link
				className={''}
				to={documentRouting.getUrl({
					id,
				})}
			>
				{_title}
			</Link>
		),
	},
	{
		title: 'Тип документа',
		key: '_type',
		dataIndex: '_type',
		width: '15%',
		render: (_type: string) => <>{_type || '-'}</>,
	},
	{
		title: 'Дата создания',
		key: '_date',
		dataIndex: '_date',
		width: '15%',
		render: (_date: string) => (
			<>{_date ? moment(_date).format(DATE_FORMAT) : '-'}</>
		),
	},
	{
		title: 'Пересекающиеся данные',
		key: '_nerAll',
		dataIndex: '_nerAll',
		...getAutocompleteFilterColumnProps('Введите значение', options),
		filteredValue: filters._nerAll,
		
		width: '30%',
		render: (_nerAll) => (
			<div>
				{_nerAll}
			</div>
		),
	},
	{
		title: '',
		key: 'id',
		dataIndex: 'id',
		width: '10%',
		sorter: false,
		render: (id) => (
			<IdentitySources onClick={() => onCellClick(id)}/>
		),
	},
];
