import { FC, ReactNode } from 'react';

import classes from './SearchSummary.module.scss';

export interface SearchSummaryProps {
    renderSummary(count: number, highlightClass: string): ReactNode;
    count?: number;
    endNode?: ReactNode;
}

export const SearchSummary: FC<SearchSummaryProps> = (props) => {
    const { renderSummary, count, endNode } = props;

    return (
        <div className={classes['search-summary']}>
            <div>{renderSummary(count ?? 0, classes['subject-count'])}</div>
            {endNode && <div className={classes['end-node']}>{endNode}</div>}
        </div>
    );
};
