import { useContext } from 'react';
import { StoresContext } from 'src/utils/mobx-store-utils/StoresContext';
import { IRootStore } from 'src/applications/base/app/features/root-store/IRootStore';

/**
 * Хук, возвращающий объект RootStore
 */
export const useRootStore = <
    T extends Record<string, unknown>
>(): IRootStore<T> => {
    const rootStore = useContext(StoresContext);
    if (!rootStore) {
        throw new Error(
            'Root Store must be set up in order to use useRootStore hook'
        );
    }
    return rootStore;
};
