import { ThemeProvider } from '@nkc-frontend/cat-design';
import { FC, ReactNode, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import ru from 'antd/lib/locale/ru_RU';

import classes from './Root.module.scss';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import {
    NotificationSlice,
    ProfileSlice,
} from 'src/applications/base/types/stores';
import { LoadingBar } from 'src/applications/base/app/react-app/components/LoadingBar';
import { ErrorNotifier } from 'src/applications/base/app/react-app/components/ErrorNotifier';

export interface RootProps {
    routes: ReactNode;
}

const Root: FC<RootProps> = ({ routes }) => {
    const { notifications, profiles } = useStores<
        NotificationSlice & ProfileSlice
    >();

    useEffect(() => {
        notifications.startPolling();
    }, [notifications]);
    useEffect(() => {
        profiles.fetchProfile();
    }, [profiles]);

    return (
        <ThemeProvider theme={'theme-base'}>
            <ConfigProvider locale={ru}>
                <ErrorNotifier />
                <LoadingBar />
                <div className={classes.root}>{routes}</div>
            </ConfigProvider>
        </ThemeProvider>
    );
};

export default Root;
