import React, { useCallback } from 'react';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import classNames from 'classnames';
import { FontAwesomeIcons } from 'src/applications/base/app/utils/constants';
import { FilterDropdownProps } from 'antd/lib/table/interface';

import classes from './InputFilterDropdown.module.scss';

export interface IInputFilterDropDownProps extends FilterDropdownProps {
    placeholder?: string;
}

export const InputFilterDropdown: React.FC<IInputFilterDropDownProps> =
    React.memo((props) => {
        const {
            placeholder,
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters: clear,
        } = props;

        const onInputChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedKeys &&
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
            },
            [setSelectedKeys]
        );

        const confirmFilters = useCallback(() => {
            confirm && confirm();
        }, [confirm]);

        const clearFilters = useCallback(() => {
            clear && clear();
        }, [clear]);

        return (
            <div className={classes['dropdown-filter']}>
                <Input
                    className={classes['input']}
                    placeholder={placeholder}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={onInputChange}
                    onPressEnter={confirmFilters}
                />
                <Button
                    className={classes['search-button']}
                    type='primary'
                    onClick={confirmFilters}
                    icon={
                        <i
                            className={classNames(
                                FontAwesomeIcons.SEARCH,
                                classes['search-icon']
                            )}
                        />
                    }
                    size='small'
                >
                    Поиск
                </Button>
                <Button
                    className={classes['reset-button']}
                    onClick={clearFilters}
                    size='small'
                >
                    Сброс
                </Button>
            </div>
        );
    });
