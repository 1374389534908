import classNames from 'classnames';
import { forwardRef, PropsWithChildren } from 'react';

import classes from './BaseHeader.module.scss';

export interface BaseHeaderProps {
    className?: string;
}

export const BaseHeader = forwardRef<
    HTMLHeadElement,
    PropsWithChildren<BaseHeaderProps>
>(({ className, children }, ref) => {
    return (
        <header className={classNames(className, classes.header)} ref={ref}>
            {children}
        </header>
    );
});
