import type { DataNode } from 'rc-tree/es/interface';
import {
    NamespaceSchemaModel,
    SchemaValue,
} from 'src/applications/base/types/namespace-schema';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isNull from 'lodash/isNull';
import classes from './DocumentAdditionalProperties.module.scss';
import { DateFormatter } from 'src/applications/base/app/utils/DateFormatter';

const validateSourceDataType = (
    sourceDataValue: unknown,
    schemaPropertyValue: SchemaValue
) => {
    const propertyValueKind = schemaPropertyValue.kind;

    if (propertyValueKind === 'binary') {
        return false;
    }

    if (isNull(sourceDataValue)) {
        return true;
    }

    if (
        propertyValueKind === 'keyword' ||
        propertyValueKind === 'text' ||
        propertyValueKind === 'date'
    ) {
        return isString(sourceDataValue);
    }

    if (propertyValueKind === 'numeric') {
        return isNumber(sourceDataValue);
    }

    if (propertyValueKind === 'bool') {
        return isBoolean(sourceDataValue);
    }

    if (propertyValueKind === 'product') {
        return isObject(sourceDataValue);
    }

    return false;
};

const validateSourceDataNotEmpty = (sourceDataValue: unknown): boolean => {
    return sourceDataValue !== '' && sourceDataValue !== null;
};

const validateSourceDataValue = (
    sourceDataValue: unknown,
    schemaPropertyValue: SchemaValue
): boolean => {
    return (
        !!schemaPropertyValue.visible &&
        validateSourceDataNotEmpty(sourceDataValue) &&
        validateSourceDataType(sourceDataValue, schemaPropertyValue)
    );
};

const getDocumentTreeData = (
    namespaceSchemaProperties: NamespaceSchemaModel['properties'],
    sourceData: Record<string, unknown>,
    nodeKeyPrefix = ''
): DataNode[] => {
    return Object.entries(namespaceSchemaProperties)
        .filter(([schemaPropertyKey, schemaPropertyValue]) => {
            const sourceDataValue = sourceData[schemaPropertyKey];

            if (isArray(sourceDataValue)) {
                return (
                    sourceDataValue.length > 0 &&
                    sourceDataValue.every((value) =>
                        validateSourceDataType(value, schemaPropertyValue)
                    ) &&
                    sourceDataValue.some((value) =>
                        validateSourceDataNotEmpty(value)
                    )
                );
            }

            return validateSourceDataValue(
                sourceDataValue,
                schemaPropertyValue
            );
        })
        .map(([schemaPropertyKey, schemaPropertyValue]) => {
            const treeNode: DataNode = {
                // префикс нужен для вложенных ключей,
                // которые могут повторяться на других уровнях вложенности
                key: `${nodeKeyPrefix}_${schemaPropertyKey}`,
                isLeaf: !schemaPropertyValue.properties,
            };

            const sourceDataValue = sourceData[schemaPropertyKey];

            if (isArray(sourceDataValue)) {
                treeNode.title = (
                    <span className={classes['property-name']}>
                        {schemaPropertyValue.displayName || schemaPropertyKey}
                    </span>
                );
                treeNode.children = sourceDataValue
                    .filter(validateSourceDataNotEmpty)
                    .map((value, index) =>
                        isObject(value) && schemaPropertyValue.properties
                            ? {
                                  title: `значение ${index + 1}`,
                                  children: getDocumentTreeData(
                                      schemaPropertyValue.properties,
                                      value as Record<string, unknown>,
                                      `${nodeKeyPrefix}_${schemaPropertyKey}_${index}`
                                  ),
                                  key: `${nodeKeyPrefix}_${schemaPropertyKey}_${index}`,
                              }
                            : {
                                  key: `${nodeKeyPrefix}_${schemaPropertyKey}_${index}`,
                                  title: value.toString(),
                                  isLeaf: true,
                              }
                    );
            } else if (isObject(sourceDataValue)) {
                treeNode.title = (
                    <span className={classes['property-name']}>
                        {schemaPropertyValue.displayName || schemaPropertyKey}
                    </span>
                );
                treeNode.children = schemaPropertyValue.properties
                    ? getDocumentTreeData(
                          schemaPropertyValue.properties,
                          sourceData[schemaPropertyKey] as Record<
                              string,
                              unknown
                          >,
                          `${nodeKeyPrefix}_${schemaPropertyKey}`
                      )
                    : undefined;
            } else {
                treeNode.title = (
                    <>
                        <span className={classes['property-name']}>
                            {schemaPropertyValue.displayName ||
                                schemaPropertyKey}
                            :
                        </span>{' '}
                        {/* TODO: отрефакторить (лучше весь метод) */}
                        {schemaPropertyValue.kind === 'date'
                            ? DateFormatter.fromIsoString(
                                  (sourceDataValue as any)?.toString()
                              ).toDocumentCardTree()
                            : (sourceDataValue as any)?.toString()}
                    </>
                );
            }
            return treeNode;
        }, []);
};

export default getDocumentTreeData;
