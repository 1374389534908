import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classes from './DocumentData.module.scss';
import { FontAwesomeIcons } from '../../../../utils/constants';
import classNames from 'classnames';
import {
    DocumentCard,
    NerTypes,
    NerTitles,
} from '../../../../../types/document/documents';
import { DocumentBlock } from '../DocumentBlock';

export const DocumentData: FC<{ documentCard: DocumentCard }> = observer(
    ({ documentCard }) => {
        const [isOpen, setIsOpen] = useState(true);
        const nerFull = documentCard.nersFull;
        if (!nerFull) {
            return <></>;
        }

        return (
            <div className={classes['documentData']}>
                <div
                    className={classNames(classes['documentData__toggle'], {
                        [classes['isOpen']]: !isOpen,
                    })}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    Выделенные из текста данные{' '}
                    <i className={FontAwesomeIcons.CHEVRON_DOWN} />
                </div>

                {isOpen && (
                    <div className={classes['documentData__container']}>
                        {Object.keys(NerTitles).map((k) => {
                            const key = k as NerTypes;
                            return (
                                <DocumentBlock
                                    key={k}
                                    someNer={nerFull[key]}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
);
