import classes from './SpellCheck.module.scss';
import { useStores } from '../../../../../../../../utils/mobx-store-utils/useStores';
import { SearchSlice } from '../../../../../../types/stores';
import { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

export const SpellCheck: FC<any> = observer(() => {
    const { search } = useStores<SearchSlice>();

    function onSearchClick(e: any) {
        e.preventDefault();
        search.disableSpellcheck = true;
        search.load();
    }

    const getCurrentResult = search.searchResults.getCurrentResult();
    const getLastSearchQuery = search.getLastSearchQuery();
    const searchResult = useMemo(
        () => getCurrentResult,
        [getCurrentResult]
    ) || { spellcheckSearchMode: '', spellcheckedQuery: '' };
    const { spellcheckSearchMode, spellcheckedQuery } = searchResult;
    const searchQuery = useMemo(() => getLastSearchQuery, [getLastSearchQuery]);
    return (
        <>
            {spellcheckSearchMode && spellcheckSearchMode !== 'Original' ? (
                <div className={classes.spellCheck}>
                    <div className={classes.spellCheck__container}>
                        {spellcheckSearchMode === 'Both' ? (
                            <div>
                                Добавлены результаты по запросу «
                                {spellcheckedQuery}».{' '}
                                <a href='/' onClick={onSearchClick}>
                                    Отменить
                                </a>
                            </div>
                        ) : (
                            <div>
                                Показаны результаты по запросу «
                                {spellcheckedQuery}».
                                <br />
                                Искать{' '}
                                <a href='/' onClick={onSearchClick}>
                                    {searchQuery}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
});
