import { interfaces, traverseAncerstors } from 'inversify';

/**
 * то же, что typeConstraint() из inversify, но принимает symbol как аргумент
 * @param type
 */
const symbolTypeConstraint =
    (type: symbol[]) => (req: interfaces.Request | null) => {
        if (req !== null) {
            const binding = req.bindings[0];

            const serviceIdentifier = binding.serviceIdentifier;

            return type.includes(serviceIdentifier as symbol);
        }
        return false;
    };

/**
 * то же самое, что и .whenInjectedInto() из inversify, но принимает symbol как аргумент.
 * использовать с .when()
 * @param serviceIdentifier
 * @example
 * bind(fooSymbol).to(SomeClass).when(whenInjectedInto(barSymbol));
 */
export const whenInjectedInto =
    (...serviceIdentifier: symbol[]) =>
    (req: interfaces.Request): boolean =>
        symbolTypeConstraint(serviceIdentifier)(req.parentRequest);

/**
 * то же самое, что и .whenAnyAncestorIs() из inversify, но принимает symbol как аргумент.
 * использовать с .when()
 * @param ancestorServiceIdentifier
 * @example
 * bind(fooSymbol).to(SomeClass).when(whenAnyAncestorIs(barSymbol));
 */
export const whenAnyAncestorIs =
    (...ancestorServiceIdentifier: symbol[]) =>
    (req: interfaces.Request): boolean =>
        traverseAncerstors(req, (anc) => {
            return symbolTypeConstraint(ancestorServiceIdentifier)(anc);
        });
