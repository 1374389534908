import { Button } from '@nkc-frontend/cat-design';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useToggle } from 'react-use';
import classes from './DocumentProperty.module.scss';
import { Tag } from 'antd';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import { SearchSlice } from '../../../../../types/stores';
import { useHistory } from 'react-router-dom';
import { useService } from '@redtea/react-inversify';
import { SearchRoute } from '../../../../../types/routing';
import { COMMON_DEP } from '../../../../../inversify/dependencyIdentifiers';
import { FilterFieldType } from '../../../../../types/document/documents';

export interface DocumentPropertyProps {
    className?: string;
    title?: string;
    content?: string;
    tags?: any[];
    isTags?: boolean;
    /**
     * Обрезает содержимое до указанного количества строк, добавляется кнопка "Показать еще"
     */
    truncate?: number;
    visible?: boolean;
}

export const DocumentProperty: React.FC<DocumentPropertyProps> = ({
    className,
    title,
    content,
    truncate,
    visible,
    tags,
    isTags,
}) => {
    const [isTruncated, toggleIsTruncated] = useToggle(true);
    const [showButton, toggleShowButton] = useToggle(false);
    const contentRef = useRef<HTMLParagraphElement>(null);
    const history = useHistory();
    const { search: searchRoute } = useService<SearchRoute>(
        COMMON_DEP.ClientRouting
    );

    const { search } = useStores<SearchSlice>();
    useEffect(() => {
        search.settings.fetchSearchSettings();
        search.loadNew();
    }, [search]);

    const isPropertyVisible = visible ?? (!!content || !!tags?.length);

    useLayoutEffect(() => {
        const contentElement = contentRef.current;
        if (!contentElement) {
            return;
        }

        toggleShowButton(
            contentElement.scrollHeight > contentElement.clientHeight
        );
    }, [toggleShowButton, content, tags]);

    const loadSearchResults = useCallback(
        (tag) => {
            const searchAlias = search.getLastSuccessfulAlias();
            const filter: any = Array.from(
                search.settings.filterStore.getFilterMap().values()
            ).find((f) => f.field === FilterFieldType.Tag);
            search.settings.activeFilterStore.addValue(filter.id, tag.code);
            search.load();

            //редирект на дефолтную страницу при наличии alias
            if (typeof searchAlias === 'string') {
                history.push(searchRoute.getUrl({ alias: searchAlias }));
            }
        },
        [search, history, searchRoute]
    );

    return isPropertyVisible ? (
        <section className={className}>
            <h2 className={classes['title']}>{title}</h2>
            {isTags ? (
                <p
                    ref={contentRef}
                    style={
                        truncate && isTruncated
                            ? {
                                  WebkitLineClamp: truncate,
                                  maxHeight: '120px',
                                  overflow: 'hidden',
                              }
                            : undefined
                    }
                    className={classes['tags']}
                >
                    {tags &&
                        tags.map((tag, index) => (
                            <Tag
                                key={index}
                                className={classes['tag']}
                                onClick={() => loadSearchResults(tag)}
                            >
                                #{tag.title}
                            </Tag>
                        ))}
                </p>
            ) : (
                <p
                    ref={contentRef}
                    style={
                        truncate && isTruncated
                            ? { WebkitLineClamp: truncate }
                            : undefined
                    }
                    className={classes['content']}
                >
                    {content}
                </p>
            )}
            <Button
                className={classes['show-more-btn']}
                visualStyle='plain-link'
                size='S'
                onClick={() => {
                    toggleIsTruncated();
                }}
            >
                {showButton && (isTruncated ? 'Показать еще' : 'Свернуть')}
            </Button>
        </section>
    ) : null;
};
