import moment from 'moment';
import { DATE_FORMAT, FULL_DATE_TIME_FORMAT } from './constants';

export function formatDatesInObject(obj: any): any {
	for (const key in obj) {
		if (typeof obj[key] === 'string') {
			if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(obj[key]) || /^\d{13}$/.test(obj[key])) {
				obj[key] = getDate(obj[key], FULL_DATE_TIME_FORMAT);
			} else if (/^\d{4}-\d{2}-\d{2}$/.test(obj[key])) {
				obj[key] = getDate(obj[key], DATE_FORMAT);
			} else if (obj[key] === 'true') {
				obj[key] = 'Да';
			} else if (obj[key] === 'false') {
				obj[key] = 'Нет';
			}
		} else if (typeof obj[key] === 'boolean') {
			obj[key] = obj[key] ? 'Да' : 'Нет';
		} else if (typeof obj[key] === 'object' && obj[key] !== null) {
			obj[key] = formatDatesInObject(obj[key]);
		}
	}
	return obj;
	
	function getDate(val: string, format: string): string {
		const date = moment(val);
		return date.isValid() ? moment(val).format(format) : val;
	}
}
