import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import { DocumentSlice, PendingRequestSlice } from '../../../../../types/stores';
import {
	NerFilters,
	NerFull, NerModel,
	NerTitles,
	NerTypes,
	SomeNer,
} from '../../../../../types/document/documents';
import { useComputed } from '../../../hooks/useComputed';
import {
	OptionGroup,
} from '../../../components/ColumnWithFilter/AutocompleteDropDown/AutocompleteDropDown';
import { generateNerText } from '../common';
import { useService } from '@redtea/react-inversify';
import { DocumentCardRoute } from '../../../../../types/routing';
import { COMMON_DEP } from '../../../../../inversify/dependencyIdentifiers';
import {
	FilterTable,
	useFilterTableFilters,
} from '../../../components/common/admin/tables/FilterTable';
import { TablePaginationConfig } from 'antd/es/table';
import classes from '../Similarity/SimilarityList.module.scss';
import { getDocumentsColumns } from './getDocumentsColumns';

export const Ners: FC<{ documentId: string, nerFilterValue: string }> = observer(
	({ documentId, nerFilterValue }) => {
		const { documents: documentStore, pendingRequest } = useStores<
			DocumentSlice & PendingRequestSlice
		>();
		
		const nersFull = documentStore.getDocument(documentId)?.nersFull as NerFull;
		
		const nerTypesFromSelect = useComputed(() => {
			const list: OptionGroup<string>[] = [];
			if (nersFull) {
				Object.keys(NerTitles).forEach((k) => {
					const key = k as NerTypes;
					if (nersFull[key]) {
						const someNer: SomeNer[] = nersFull[key];
						if (someNer?.length) {
							const options = someNer.map(n => ({
								label: generateNerText(n),
								value: n.hash,
							}));
							if (options.length) {
								list.push({
									label: NerTitles[key],
									options,
								});
							}
						}
					}
				});
			}
			return list;
		}, [nersFull]);
		
		const basePagination = {
			current: 1,
			pageSize: 10,
		};
		
		const [paginationInit, setPagination] = useState(basePagination);
		
		const { document: documentRouting } = useService<DocumentCardRoute>(COMMON_DEP.ClientRouting);
		
		const nerList = documentStore.nerList || [];
		
		const totalCountNer = useComputed(
			() => documentStore.nerListCount,
			[documentStore],
		);
		const {
			filters: filtersNer,
			handleTableChange: handleTableChangeNer,
			resetFilters: resetFiltersNer,
		} =
			useFilterTableFilters<NerFilters>({ _title: [], _nerAll: [] });
		
		const startSearch = useCallback(
			(
				{ current, pageSize }: TablePaginationConfig,
				filters: NerFilters,
			) => {
				const body: any = {};
				if (current && pageSize) {
					body.skip = (current - 1) * pageSize;
					body.limit = pageSize;
				}
				
				body.filters = filters;
				body.documentId = documentId;
				
				documentStore.fetchNer({
					id: body.documentId,
					from: body.skip,
					limit: body.limit,
					title: body.filters._title?.[0] || undefined,
					ner: body.filters._nerAll?.[0] || undefined,
				});
			},
			[documentStore, documentId],
		);
		
		const onNerPageChange = useCallback((current: number, pageSize: number = 10) => {
				setPagination({ current, pageSize});
			},
			[setPagination],
		);
		const [selectedRowKey, setSelectedRowKey] = useState('');
		
		const rowClassName = (row: NerModel) => {
			const rowClasses = [];
			if (row.id === selectedRowKey) {
				rowClasses.push(classes['selected-row']);
			}
			return rowClasses.join(' ');
		};
		
		const onCellClick = useCallback(
			(sourceDocumentId: string) => {
				documentStore.openSideBarNer(documentId, sourceDocumentId);
				setSelectedRowKey(sourceDocumentId);
			},
			[documentStore, documentId],
		);
		
		
		const columnsNer = useMemo(
			() => getDocumentsColumns(
				filtersNer,
				documentRouting,
				onCellClick,
				nerTypesFromSelect,
			),
			[filtersNer, documentRouting, onCellClick, nerTypesFromSelect],
		);
		
		useEffect(() => {
			if (!documentStore.sourceDocumentId) {
				setSelectedRowKey('');
			}
		}, [documentStore.sourceDocumentId]);
		
		const pagination = useMemo(()=> {
			return {
				current: paginationInit.current,
				pageSize: paginationInit.pageSize,
				onChange: onNerPageChange,
				total: totalCountNer,
			}
		}, [paginationInit, totalCountNer, onNerPageChange]);
		
		
		const [nerFilter, setNerFilter] = useState<string>();
		
		useEffect(() => {
			setNerFilter(nerFilterValue || '')
		}, [nerFilterValue]);
		
		useEffect(() => {
			if(nerFilter){
				const filters = {_nerAll: [nerFilter]}
				handleTableChangeNer(pagination, filters, {}, {
					action: 'filter', currentDataSource: [],
				})
				startSearch(pagination, filters as any);
				setNerFilter("");
			}
		}, [nerFilter, pagination, handleTableChangeNer, startSearch]);
		
		const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
			setNerFilter('');
			handleTableChangeNer(pagination, filters, sorter, extra);
			startSearch(
				pagination,
				filters,
			);
		};
		
		const onNerResetFiltersClick = () => {
			resetFiltersNer();
			setNerFilter('');
			startSearch(pagination, {_title: [], _nerAll: []});
		};
		
		return (
			<FilterTable
				rowClassName={rowClassName}
				className={classes['similarityTable']}
				loading={pendingRequest.isPending('DOCUMENT_VIEWED')}
				pagination={pagination}
				columns={columnsNer}
				dataSource={nerList}
				rowKey={(tab) => tab.id}
				onChange={onChange}
				onResetFiltersClick={onNerResetFiltersClick}
			/>
		);
	},
);


