import { EMPTY, Observable, OperatorFunction, pipe } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IPendingRequestStore } from 'src/applications/base/app/features/pending-request-store/IPendingRequestStore';
import { IRequestErrorStore } from 'src/applications/base/app/features/request-error-store/IRequestErrorStore';

type ReqName = string | (() => string);

/* ------------------------------- START/END REQUEST IN REQUEST STORE ------------------------------- */

export interface PendingRequestStoreObj {
    pendingRequestStore: IPendingRequestStore;
}

/**
 * использует поле pendingRequestStore: IPendingRequestStore, чтобы отметить начало реквеста
 */
export const startRequest = <T>(o: PendingRequestStoreObj, reqName: ReqName) =>
    tap<T>(() =>
        o.pendingRequestStore.startRequest(
            typeof reqName === 'function' ? reqName() : reqName
        )
    );

/**
 * использует поле pendingRequestStore: IPendingRequestStore, чтобы отметить конец реквеста
 */
export const endRequest = <T>(o: PendingRequestStoreObj, reqName: ReqName) => {
    const endReq = () =>
        o.pendingRequestStore.endRequest(
            typeof reqName === 'function' ? reqName() : reqName
        );

    return tap<T>(endReq, endReq);
};

/* ------------------------------- ERRORS -------------------------- */

export interface RequestErrorStoreObj {
    requestErrorStore: IRequestErrorStore;
}

/**
 * использует поле requestErrorStore: IRequestErrorStore, чтобы в случае ошибки записать её
 */
export const handleError = <T>(
    o: RequestErrorStoreObj,
    reqName: ReqName
): OperatorFunction<T, T> =>
    catchError((e) => {
        console.error(e);
        o.requestErrorStore.error(
            typeof reqName === 'function' ? reqName() : reqName,
            e
        );
        return EMPTY;
    });

/* ------------------------------- END REQUEST HANDLERS -------------------------- */
/**
 * объединяет endRequest() и handleError()
 */
export const endRequestWithErrorHandler = <T>(
    o: PendingRequestStoreObj & RequestErrorStoreObj,
    reqName: ReqName
) =>
    pipe<Observable<T>, Observable<T>, Observable<T>>(
        endRequest(o, reqName),
        handleError(o, reqName)
    );
