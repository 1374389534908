import { FC, useMemo, useState } from 'react';
import { ListSorting } from 'src/applications/base/app/react-app/components/common/ListSorting';
import { SortOption as ListSortOption } from 'src/applications/base/app/react-app/components/common/ListSorting/ListSorting';

interface SortOption {
    code: string | null;
    label: string;
}

export interface SearchSortingProps {
    sortingOptions: SortOption[];
    activeCode: string | null;
    activeOrder: string | null;
    /**
     * добавит к опциям вариант "по релевантности" с code=null
     */
    enableRelevancyOption?: boolean;
    onChange(code: string | null, order: 'ASC' | 'DESC' | null): void;
}

const relevancyOption: ListSortOption = {
    label: 'По релевантности',
    code: null,
    order: null,
};

export const SearchSorting: FC<SearchSortingProps> = (props) => {
    const {
        sortingOptions,
        enableRelevancyOption,
        onChange,
        activeOrder,
        activeCode,
    } = props;

    const [isListOpened, setIsListOpened] = useState(false);

    const options = useMemo(() => {
        const result: ListSortOption[] = enableRelevancyOption
            ? [relevancyOption]
            : [];
        sortingOptions.forEach((sortOpt) => {
            result.push({
                ...sortOpt,
                order: 'ASC',
                label: `${sortOpt.label} по возрастанию`,
            });
            result.push({
                ...sortOpt,
                order: 'DESC',
                label: `${sortOpt.label} по убыванию`,
            });
        });
        return result;
    }, [sortingOptions, enableRelevancyOption]);

    return (
        <>
            {options.length ? <ListSorting
                listOpened={isListOpened}
                options={options}
                activeOrder={activeOrder}
                activeCode={activeCode}
                onChange={(opt) => {
                    onChange(opt.code, opt.order);
                    setIsListOpened(false);
                }}
                onLabelClick={() => setIsListOpened(true)}
                onMenuOutsideClick={() => setIsListOpened(false)}
            /> : ""}
        </>
        
    );
};
