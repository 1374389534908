import { Icon, LinkButton, MenuItem } from '@nkc-frontend/cat-design';
import { useService } from '@redtea/react-inversify';
import React from 'react';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { ClientRouting } from 'src/applications/base/types/routing';

import classes from './NavigationMenuItems.module.scss';

export const NavigationMenuItems: React.FC = () => {
    const { adminTabs, main, catalog }: ClientRouting = useService(
        COMMON_DEP.ClientRouting
    );

    return (
        <>
            <MenuItem>
                <LinkButton
                    visualStyle={'plain'}
                    to={main.getUrl()}
                    before={
                        <Icon
                            size={'M'}
                            className={classes['navigation-icon']}
                            icon='search'
                        />
                    }
                >
                    Поиск
                </LinkButton>
            </MenuItem>
            <MenuItem>
                <LinkButton
                    to={catalog.getUrl()}
                    visualStyle={'plain'}
                    before={
                        <Icon
                            size={'M'}
                            className={classes['navigation-icon']}
                            icon='catalog'
                        />
                    }
                >
                    Каталог
                </LinkButton>
            </MenuItem>
            <MenuItem>
                <LinkButton
                    visualStyle={'plain'}
                    to={adminTabs.getUrl()}
                    before={
                        <Icon
                            size={'M'}
                            className={classes['navigation-icon']}
                            icon='admin'
                        />
                    }
                >
                    Администрирование
                </LinkButton>
            </MenuItem>
        </>
    );
};
