import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { usePrevious } from 'react-use';

export const END_ANIMATION_DURATION = 200;

type Opts = {
    loading: boolean;
    updateTime?: number;
    progressIncrease?: number;
    maxProgress?: number;
};

const transitionFunc = 'cubic-bezier(0.61, 1, 0.88, 1)';

const getAnimationStyles = (percent: number, animationDuration: number) => ({
    width: `${percent}%`,
    transition: `width ${animationDuration}ms ${transitionFunc} 0s`,
    msTransition: `width ${animationDuration}ms ${transitionFunc} 0s`,
    WebkitTransition: `width ${animationDuration}ms ${transitionFunc} 0s`,
    MozTransition: `width ${animationDuration}ms ${transitionFunc} 0s`,
    OTransition: `width ${animationDuration}ms ${transitionFunc} 0s`,
    willChange: 'width, opacity',
});

const removeTransitionStyles = () => ({
    transition: ``,
    msTransition: ``,
    WebkitTransition: ``,
    MozTransition: ``,
    OTransition: ``,
});

export const useLoadingBar = <TElement extends HTMLElement>({
    loading,
    updateTime = 500,
    progressIncrease = 5,
    maxProgress = 99,
}: Opts) => {
    const rafRef = useRef<number | null>(null);
    const stopTimeoutRef = useRef<number | null>(null);
    const startTimeoutRef = useRef<number | null>(null);

    const prevLoading = usePrevious(loading);

    const elementRef = useRef<TElement | null>(null);

    const applyStylesToElement = (styles: Record<string, string>) => {
        if (elementRef.current) {
            Object.entries(styles).forEach(([property, style]) => {
                (elementRef.current!.style as any)[property] = style;
            });
        }
    };

    const startProgress = useCallback(() => {
        if (stopTimeoutRef.current) {
            window.clearTimeout(stopTimeoutRef.current);
        }
        applyStylesToElement({ width: '0' });

        startTimeoutRef.current = window.setTimeout(() => {
            applyStylesToElement({ opacity: '1' });

            const animationDuration = (100 / progressIncrease) * updateTime;

            applyStylesToElement(
                getAnimationStyles(maxProgress, animationDuration)
            );
        }, updateTime);
    }, [updateTime, progressIncrease, maxProgress]);

    const stopProgress = useCallback(() => {
        if (rafRef.current !== null) {
            window.cancelAnimationFrame(rafRef.current);
            rafRef.current = null;
        }
        if (startTimeoutRef.current !== null) {
            window.clearTimeout(startTimeoutRef.current);
            startTimeoutRef.current = null;
        }

        applyStylesToElement(getAnimationStyles(100, END_ANIMATION_DURATION));

        stopTimeoutRef.current = window.setTimeout(() => {
            applyStylesToElement({ opacity: '0', width: '0' });
            applyStylesToElement(removeTransitionStyles());

            stopTimeoutRef.current = null;
        }, END_ANIMATION_DURATION + 10);
    }, []);

    useLayoutEffect(() => {
        applyStylesToElement({ opacity: '0', width: '0' });
    }, []);

    useEffect(() => {
        if (loading && !prevLoading) {
            startProgress();
        } else if (!loading && prevLoading) {
            stopProgress();
        }
    }, [loading, prevLoading, startProgress, stopProgress]);

    return elementRef;
};
