import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { SearchTab } from 'src/applications/base/types/search/searchAPI';
import { ISorting } from 'src/applications/base/app/features/sorting/ISorting';
import { SearchSummary } from 'src/applications/base/app/react-app/components/common/search-result/SearchSummary';
import classes from './SearchSummaryWithSettings.module.scss';
import { SearchSorting } from 'src/applications/base/app/react-app/components/common/SearchSorting';
import { CountedItems } from 'src/applications/base/app/features/search/stores/SearchResultStore/CountedItems';

export interface SearchSummarySearchStore {
    settings: {
        getActiveTab(): SearchTab | null;
        sorting: ISorting;
    };
    searchResults: {
        getCurrentResult(): CountedItems | null;
    };
    load(): void;
    loadDocuments(): void;
}

export interface SearchSummaryWithSettingsProps {
    search?: SearchSummarySearchStore;
    className?: string;
    sorting?: boolean;
    extraSettingsNode?: ReactNode;
    /**
     * добавит к опциям сортировки вариант "по релевантности" с code=null
     */
    enableRelevancySortOption?: boolean;
    renderSummary(count: number, highlightClass: string): ReactNode;
}

export const SearchSummaryWithSettings: FC<SearchSummaryWithSettingsProps> =
    observer(
        ({
            search,
            className,
            renderSummary,
            extraSettingsNode,
            sorting,
            enableRelevancySortOption = window.appConfig.enableRelevancyOption,
        }) => {
            if (!search) {
                return null;
            }

            const activeTab = search.settings.getActiveTab();
            const currentTabResults = search.searchResults.getCurrentResult();

            return (
                <div className={className}>
                    <SearchSummary
                        renderSummary={renderSummary}
                        count={currentTabResults?.totalCount}
                        endNode={
                            <div className={classes.settings}>
                                {sorting && (
                                    <SearchSorting
                                        activeCode={
                                            search.settings.sorting.code
                                        }
                                        activeOrder={
                                            search.settings.sorting.order
                                        }
                                        sortingOptions={
                                            activeTab?.sortOptions?.options ??
                                            []
                                        }
                                        enableRelevancyOption={
                                            enableRelevancySortOption
                                        }
                                        onChange={(
                                            code: string | null,
                                            order: 'ASC' | 'DESC' | null
                                        ) => {
                                            search.settings.sorting.setCode(
                                                code
                                            );
                                            search.settings.sorting.setOrder(
                                                order
                                            );
                                            const activeTab = search?.settings?.getActiveTab()
                                            activeTab?.code !== 'favorites'
                                                ? search?.load()
                                                : search?.loadDocuments()
                                        }}
                                    />
                                )}
                                {extraSettingsNode}
                            </div>
                        }
                    />
                </div>
            );
        }
    );
