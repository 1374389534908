import {FC, useState} from 'react';
import {Footer as FooterComponent, Icon} from '@nkc-frontend/cat-design';
import { FooterProps } from '@nkc-frontend/cat-design/dist/components/Footer';
import {Dialog} from "../../../../base/app/react-app/components/common/Dialog";
import classes from './Footer.module.scss';
type Props = Omit<FooterProps, 'items'>;

export const Footer: FC<Props> = (props) => {
    const [showModal, setShowModal] = useState( false);
    const closeModal = () => {
        setShowModal(false);
    }
    return (
        <div className={classes['footer-without-logo']}>
            <FooterComponent
                {...props}
                items={[
                    <a  href={'/'} key={1} onClick={(e)=> {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowModal(true)
                    }}>
                        О системе
                    </a>,
                    
                    <a href={'mailto:support@fcpriirf.ru'}  key={1}>
                        Обратная связь
                    </a>,
                    <Dialog
                        className={classes['modal-about-dialog']}
                        key={1}
                        visible={showModal}
                        onClose={closeModal}
                        closeIcon={<Icon icon={'close'} size={'M'} />}
                    >
                        <div className={classes['modal-about-img']}></div>
                    
                    </Dialog>
                ]}
            />
        </div>
       
    );
};
