import { DocumentCard } from 'src/applications/base/types/document/documents';
import { DocumentPropertiesView } from '../DocumentPropertiesView';

interface DocumentPropertiesProps {
    document: DocumentCard;
}

export const DocumentProperties: React.FC<DocumentPropertiesProps> = ({
    document,
}) => {
    const documentContext =
        document?.content || document?.properties?.abstract?.join('\n');
    const keywords = document?.keywords?.join(', ');

    return (
        <DocumentPropertiesView
            properties={[
                {
                    name: 'keywords',
                    title: 'Ключевые слова',
                    content: keywords,
                },
                {
                    name: 'documentContext',
                    title: 'Контекст',
                    truncate: 7,
                    content: documentContext,
                },
            ]}
        />
    );
};
