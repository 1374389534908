import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import qs from 'qs';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { RequestErrorSlice } from 'src/applications/base/types/stores';
import { useComputed } from 'src/applications/base/app/react-app/hooks/useComputed';
import { Button, Icon } from '@nkc-frontend/cat-design';
import { CRUD_REQUEST_TYPE } from 'src/applications/base/app/features/utils/crud/CRUD_REQUEST_TYPE';
import { Dialog } from 'src/applications/base/app/react-app/components/common/Dialog';

import classes from './ErrorNotifier.module.scss';
import { useLocation } from 'react-router-dom';

export interface ErrorNotifierProps {
    className?: string;
}

//TODO: возможно нужно вынести в отдельное место типы ошибок и их заголовки
enum ErrorType {
    UNKNOWN,
    CREATE,
    EDIT,
    DELETE,
}

const errorType2TitleMap = new Map<number, string>([
    [ErrorType.UNKNOWN, 'Произошла ошибка при загрузке данных'],
    [ErrorType.CREATE, 'Произошла ошибка при создании объекта'],
    [ErrorType.EDIT, 'Произошла ошибка при изменении объекта'],
    [ErrorType.DELETE, 'Произошла ошибка'],
]);

export const ErrorNotifier: FC<ErrorNotifierProps> = observer(() => {
    const { requestError } = useStores<RequestErrorSlice>();

    const location = useLocation();
    const ignoreAllErrors =
        qs.parse(location.search, { ignoreQueryPrefix: true })
            .disableErrorModals === 'all';

    const networkErrorList = useComputed(() => {
        if (ignoreAllErrors) {
            return [];
        }
        const errors = requestError.getErrorList();
        return errors.filter((e) => typeof e.httpCode === 'number');
    }, [requestError, ignoreAllErrors]);

    const errorMessageList = useMemo(() => {
        const errorMap = networkErrorList.reduce(
            (erType2DescriptionMap, err) => {
                let type: ErrorType;

                if (err.key.endsWith(CRUD_REQUEST_TYPE.EDIT)) {
                    type = ErrorType.EDIT;
                } else if (err.key.endsWith(CRUD_REQUEST_TYPE.CREATE)) {
                    type = ErrorType.CREATE;
                } else if (err.key.endsWith(CRUD_REQUEST_TYPE.DELETE)) {
                    type = ErrorType.DELETE;
                } else {
                    type = ErrorType.UNKNOWN;
                }

                if (erType2DescriptionMap.has(type)) {
                    const errorDescription = erType2DescriptionMap.get(type)!;
                    errorDescription.count++;
                    if (err.message) {
                        errorDescription.messages.push(err.message);
                    }
                } else {
                    const errorDescription = {
                        count: 1,
                        title: errorType2TitleMap.get(type),
                        //у одинаковых по типу ошибок могут быть разные message, поэтому рендерим лист message, чтобы не пропустить
                        messages: err.message ? [err.message] : [],
                    };
                    erType2DescriptionMap.set(type, errorDescription);
                }

                return erType2DescriptionMap;
            },
            new Map<
                ErrorType,
                { count: number; title?: string; messages: string[] }
            >()
        );

        return Array.from(errorMap.values());
    }, [networkErrorList]);

    return (
        <Dialog
            visible={errorMessageList.length > 0}
            onClose={requestError.removeAllErrors}
            closeIcon={<Icon icon={'close'} size={'M'} />}
        >
            <div className={classes['modal-body']}>
                {errorMessageList.map((err) => (
                    <div
                        key={err.title}
                        className={classes['error-title']}
                        title={err.messages.join('\n')}
                    >
                        {err.title} {err.count > 1 && `(${err.count})`}
                    </div>
                ))}
            </div>
            <div className={classes.controls}>
                <Button
                    data-testid={'error-notifier-close'}
                    onClick={requestError.removeAllErrors}
                >
                    Понятно
                </Button>
            </div>
        </Dialog>
    );
});
