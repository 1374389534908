import { AdminCatalogItemModel } from 'src/applications/base/types/admin/adminCatalogItems';
import { NerNpaValue, NerOfficial, NerValue, SomeNer } from '../../../../types/document/documents';

export const joinCatalogItemsTitles = (
    catalogItems?: AdminCatalogItemModel[]
) =>
    Array.isArray(catalogItems)
        ? catalogItems?.map((item) => item.title).join(', ')
        : undefined;


export function generateNerText(someNer: SomeNer): string {
	const { type } = someNer;
	
	if (type === 'npa') {
		const { kind, documentDate, governmentAgency, number } =
			someNer as NerNpaValue;
		return `${kind} ${governmentAgency} от ${documentDate} № ${number}`;
	}
	
	if (type === 'officials') {
		const { employeeTitle, personFullName } =
			someNer as NerOfficial;
		return `${employeeTitle} ${personFullName}`;
	}
	
	const { value } = someNer as NerValue<'LOC'>;
	return value;
}
