import { FC } from 'react';
import sprite from 'src/applications/base/images/sprite.svg';

type IconSpriteProps = {
    name: string;
    size: number;
    classSvg?: string;
};

const IconSprite: FC<IconSpriteProps> = ({ name, size, classSvg }) => {
    return (
        <svg className={classSvg} width={size} height={size}>
            <use href={`${sprite}#icon-${name}`} />
        </svg>
    );
};

export default IconSprite;
