import classNames from 'classnames';
import { FC } from 'react';

import { MainHeader } from 'src/applications/base/app/react-app/components/common/headers/MainHeader';

import classes from './MainLayout.module.scss';
import {useService} from "@redtea/react-inversify";
import {FooterComponent} from "../../../../../types/components";
import {FOOTER_DEP} from "../../../../../inversify/dependencyIdentifiers";


export interface MainLayoutProps {
    className?: string;
}

export const MainLayout: FC<MainLayoutProps> = ({ children, className }) => {
    const Footer = useService<FooterComponent>(FOOTER_DEP.FooterComponent)
    return (
        <div className={classNames(classes['main-layout'], className)}>
            <MainHeader />
            <div className={classes.children}>{children}</div>
            <Footer className={classes.footer} />
        </div>
    );
};
