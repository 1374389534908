import { useEffect, useMemo, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { TruncateRef } from '@nkc-frontend/cat-design/dist/components/Truncate/Truncate';

/**
 * внутри контента NotificationCard могут вставляться изображения, поэтому нужно повесить
 * event listener на onload, чтобы пересчитать скрытие/закрытие контента при загрузке картинок
 */
export const useCheckOverflowOnImageLoad = () => {
    const cardContentId = useMemo(() => uniqueId('content-'), []);

    const truncateRef = useRef<TruncateRef | null>(null);

    useEffect(() => {
        const el = document.querySelectorAll(`#${cardContentId} img`);
        const imgList = Array.from(el);

        const onLoad = () => {
            truncateRef.current?.checkForOverflow();
        };

        imgList.forEach((img) => {
            img.addEventListener('load', onLoad);
        });

        return () => {
            imgList.forEach((img) => {
                img.removeEventListener('load', onLoad);
            });
        };
    }, [cardContentId]);

    return [cardContentId, truncateRef] as const;
};
