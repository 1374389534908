import { useService } from '@redtea/react-inversify';
import { FC } from 'react';
import { Dropdown, Icon, Menu } from '@nkc-frontend/cat-design';

import classes from './Navigation.module.scss';
import classNames from 'classnames';
import { NavigationMenuComponent } from 'src/applications/base/types/components';
import { MENU_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';

interface INavigationProps {
    className?: string;
}

export const Navigation: FC<INavigationProps> = ({ className }) => {
    const NavigationMenu = useService<NavigationMenuComponent>(
        MENU_DEP.NavigationMenuComponent
    );

    return (
        <Dropdown
            // тут и ниже используем нестандартный z-index для дропдауна
            // это нужно, потому что хедер имеет z-index выше, чем стандартный дропдаун,
            // что в свою очередь нужно чтобы тень от хедера внизу падала на дропдаун с подсказками
            popupClassName={classes['dropdown']}
            popup={
                <Menu data-testid={'nav-menu'}>
                    <NavigationMenu />
                </Menu>
            }
            popupAlign={{
                points: ['tr', 'br'],
            }}
        >
            <span
                className={classNames(className)}
                data-testid={'nav-menu-trigger'}
            >
                <Icon
                    className={classes['navigation__icon']}
                    icon={'menu-burger'}
                    size={'M'}
                />
            </span>
        </Dropdown>
    );
};
