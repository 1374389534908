import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { matchPath, RouteComponentProps } from 'react-router-dom';
import { ProfileRoute } from 'src/applications/base/types/routing';
import { Tab, Tabs, TabsUnderline } from '@nkc-frontend/cat-design';
import { useService } from '@redtea/react-inversify';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { IPageTitleProvider } from 'src/applications/base/app/features/page-title-provider/IPageTitleProvider';

export interface ProfileTabsProps
    extends Pick<RouteComponentProps, 'location' | 'history'> {
    className?: string;
}

export const ProfileTabs: FC<ProfileTabsProps> = observer(
    ({ location, history, className }) => {
        const { profile: profileRouting } = useService<ProfileRoute>(
            COMMON_DEP.ClientRouting
        );
        const pageTitleProvider = useService<IPageTitleProvider>(
            COMMON_DEP.PageTitleProvider
        );

        const tabs = useMemo(
            () => ({
                [profileRouting.routingPattern]: {
                    title:
                        pageTitleProvider.getSubtitleByTemplate(
                            profileRouting.routingPattern
                        ) ?? '',
                    to: profileRouting.getUrl(),
                    icon: 'profile',
                },
                [profileRouting.subRoutes.favorite.routingPattern]: {
                    title:
                        pageTitleProvider.getSubtitleByTemplate(
                            profileRouting.subRoutes.favorite.routingPattern
                        ) ?? '',
                    to: profileRouting.subRoutes.favorite.getUrl(),
                    icon: 'favorite-stroke',
                },
                [profileRouting.subRoutes.notifications.routingPattern]: {
                    title:
                        pageTitleProvider.getSubtitleByTemplate(
                            profileRouting.subRoutes.notifications
                                .routingPattern
                        ) ?? '',
                    to: profileRouting.subRoutes.notifications.getUrl(),
                    icon: 'notification',
                },
            }),
            [profileRouting, pageTitleProvider]
        );

        const activeTabFromLocation = useMemo(
            () =>
                Object.keys(tabs).find((tpl) =>
                    matchPath(location.pathname, {
                        path: tpl,
                        exact: true,
                    })
                ),
            [tabs, location.pathname]
        );

        return (
            <div className={className}>
                <TabsUnderline>
                    <Tabs
                        visualStyle={'fill'}
                        active={activeTabFromLocation}
                        onChange={(tabVal) => {
                            if (tabVal && tabs[tabVal]) {
                                history.push(tabs[tabVal].to);
                            }
                        }}
                    >
                        {Object.entries(tabs).map(
                            ([tabVal, { title, icon }]) => (
                                <Tab key={tabVal} value={tabVal} icon={icon}>
                                    {title}
                                </Tab>
                            )
                        )}
                    </Tabs>
                </TabsUnderline>
            </div>
        );
    }
);
