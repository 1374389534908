import React from 'react';
import ReactDOM from 'react-dom';
import { Container } from 'inversify';
import { Context as InversifyContext } from '@redtea/react-inversify';
import { History } from 'history';
import { Router } from 'react-router-dom';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { RootComponent } from 'src/applications/base/types/components';
import { Routes } from 'src/applications/base/types/routing';
import { StoresContext } from 'src/utils/mobx-store-utils/StoresContext';
import { IRootStore } from 'src/applications/base/app/features/root-store/IRootStore';

export const setupApp = (
    container: Container,
    rootStore?: IRootStore<Record<string, unknown>>
) => {
    const routes: Routes = container.get(COMMON_DEP.RoutesNode);
    const history: History = container.get(COMMON_DEP.History);
    const Root: RootComponent = container.get(COMMON_DEP.RootComponent);

    ReactDOM.render(
        <>
            <InversifyContext.Provider value={container}>
                <StoresContext.Provider value={rootStore}>
                    <Router history={history}>
                        <Root routes={routes} />
                    </Router>
                </StoresContext.Provider>
            </InversifyContext.Provider>
        </>,
        document.getElementById('root')
    );
};
