import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classes from './SimilaritySidebar.module.scss';
import { Button, Icon, SearchInput } from '@nkc-frontend/cat-design';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import { DocumentSlice } from '../../../../../types/stores';
import { useComputed } from '../../../hooks/useComputed';
import { ReactComponent as Copy } from 'src/applications/base/images/copy.svg';
import { ReactComponent as ShowInModal } from 'src/applications/base/images/showInModal.svg';
import { Dialog } from '../../../components/common/Dialog';
import { Tooltip } from 'antd';
import { timer } from 'rxjs';

export const SimilaritySidebar: FC<{ documentId: string }> = observer(
    ({ documentId }) => {
        const { documents: documentStore } = useStores<DocumentSlice>();
        const intersections = useComputed(
            () => documentStore.intersections,
            [documentStore]
        );
        const intersectionsWithHtml = useComputed(
            () => documentStore.intersectionsWithHtml,
            [documentStore]
        );
        const [catalogItemSearchQuery, setCatalogItemSearchQuery] =
            useState<string>('');
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setCatalogItemSearchQuery(event.target.value.toLowerCase());
        };
        useEffect(() => {
            if (!documentStore.sourceDocumentId) {
                setCatalogItemSearchQuery('');
            }
        }, [documentStore.sourceDocumentId]);
        useEffect(() => {
            if (documentStore.intersections.length) {
                setCatalogItemSearchQuery('');
            }
        }, [documentStore.intersections]);

        const [openTooltip, setOpenTooltip] = useState(false);
        const [openTooltipNumber, setOpenTooltipNumber] = useState(-1);
        const copyClick = (text: string, i: number) => {
            navigator.clipboard.writeText(text).then();
            setOpenTooltip(true);
            setOpenTooltipNumber(i);
            timer(1000).subscribe((_) => {
                setOpenTooltip(false);
            });
        };

        const dialogTitle = useComputed(
            () => documentStore.sourceDocumentTitle,
            [documentStore.sourceDocumentTitle]
        );

        return (
            <>
                <div className={classes.container}>
                    <button
                        type='button'
                        className='cd-button'
                        onClick={() => documentStore.closeSideBar()}
                    >
                        <span className='cd-icon cd-icon_size_m cd-icons-close'></span>
                    </button>
                    <div className={classes.title}>Текстовые пересечения</div>
                    <div
                        className={classes.openModal}
                        onClick={() =>
                            documentStore.sourceDocumentId &&
                            documentStore.openModal(
                                documentId,
                                documentStore.sourceDocumentId
                            )
                        }
                    >
                        <ShowInModal/>
                        <span>Посмотреть в документе</span>
                    </div>
                    <div className={classes.search}>
                        <SearchInput
                            value={catalogItemSearchQuery}
                            onChange={onChange.bind(this)}
                            data-testid={'search-catalog-input'}
                            placeholder={'Поиск'}
                            visualStyle={'plain'}
                            onClear={() => setCatalogItemSearchQuery('')}
                        />
                    </div>
                    
                    <div className={classes.list}>
                        {intersections
                            .filter((i) =>
                                catalogItemSearchQuery
                                    ? i
                                        .toLowerCase()
                                        .includes(
                                            catalogItemSearchQuery.toLowerCase()
                                        )
                                    : i
                            )
                            .map((i, index) => (
                                <div className={classes.row} key={index}>
                                    <div>
                                        {i.length < 150
                                            ? i
                                            : i.substring(0, 150) + '...'}
                                    </div>
                                    <Tooltip
                                        title={
                                            'Текст скопирован в буфер обмена'
                                        }
                                        visible={
                                            openTooltip &&
                                            openTooltipNumber === index
                                        }
                                        placement={'leftTop'}
                                    >
                                        <Copy
                                            onClick={() => {
                                                copyClick(i, index);
                                            }}
                                            className={classes.icon}
                                        />
                                    </Tooltip>
                                </div>
                            ))}
                    </div>
                
                </div>
                <Dialog
                    visible={!!intersectionsWithHtml}
                    width={920}
                    height={790}
                    closeIcon={<Icon icon={'close'} size={'M'}/>}
                    onClose={() => {
                        documentStore.closeModal();
                    }}
                >
                    <div className={classes.dialog}>
                        <div>
                            <h2 className={'mt-0'}>
                                Текстовые пересечения с документом «
                                {dialogTitle}»
                            </h2>
                            <div
                                className={classes.modalContent}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        documentStore.intersectionsWithHtml +
                                        '',
                                }}
                            ></div>
                        </div>
                        <div className='d-flex justify-content-start mt-8'>
                            <Button
                                onClick={() => {
                                    documentStore.closeModal();
                                }}
                            >
                                Закрыть
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </>
        );
    }
);
