import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { Button, Pagination } from '@nkc-frontend/cat-design';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { NotificationSlice } from 'src/applications/base/types/stores';
import { toJS } from 'mobx';
import { NotificationCard } from '../NotificationCard/NotificationCard';
import { ResizeContext } from '@nkc-frontend/nkc-react-hooks';
import { fromEvent } from 'rxjs';
import { debounceTime, share } from 'rxjs/operators';

import classes from './Notifications.module.scss';

export const Notifications: FC = observer(() => {
    const { notifications } = useStores<NotificationSlice>();

    useEffect(() => {
        notifications.fetchNotificationList();

        return notifications.clearUp;
    }, [notifications]);

    const notificationList = notifications.getNotificationList();

    const currentPage =
        Math.floor(notifications.paging.skip / notifications.paging.limit) + 1;

    //создаём observable для изменения размера, чтобы каждый Truncate внутри
    //NotificationCard не создавал собственные listener'ы
    const resizeObs = useMemo(
        () => fromEvent(window, 'resize').pipe(debounceTime(300), share()),
        []
    );

    return (
        <div>
            <Button
                visualStyle={'plain-link'}
                size={'M'}
                className={classes['read-all-btn']}
                onClick={() => notifications.markAllRead()}
            >
                Отметить всё как прочитанное
            </Button>
            <div className={classes['table-header']}>
                <div className={classes['header-date']}>Дата</div>
                <div>Уведомление</div>
            </div>
            <ResizeContext.Provider value={resizeObs}>
                <div>
                    {notificationList.map((notif) => (
                        <NotificationCard
                            key={notif.id}
                            className={classes.notification}
                            markRead={notifications.markRead}
                            dateBlockClassName={classes['notification-date']}
                            mainBlockClassName={classes['notification-main']}
                            notification={notif}
                        />
                    ))}
                </div>
            </ResizeContext.Provider>
            <Pagination
                className={classes.pagination}
                current={currentPage}
                total={toJS(notifications.getTotalCount())}
                onChange={(page, pageSize) => {
                    if (pageSize !== notifications.paging.limit) {
                        if (pageSize) {
                            notifications.paging.setLimit(pageSize);
                            notifications.paging.setSkip(0);
                        }
                    } else {
                        notifications.paging.setSkip(
                            (page - 1) * notifications.paging.limit
                        );
                    }
                    notifications.fetchNotificationList();
                }}
                pageSize={notifications.paging.limit}
                pageSizeOptions={['10', '30', '50']}
            />
        </div>
    );
});
