import React from 'react';

import { ColumnProps } from 'antd/es/table';
import moment from 'moment';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { FontAwesomeIcons } from './constants';
import { DateFormat } from '../../types/table';
import { InputFilterDropdown } from '../react-app/components/common/admin/InputFilterDropdown';
import { FilterDatePicker } from '../react-app/components/common/admin/FilterDatePicker';
import classNames from 'classnames';
import { FilterDatePickerProps } from 'src/applications/base/app/react-app/components/common/admin/FilterDatePicker/FilterDatePicker';
import {
    AdminCatalogModel,
    CatalogItemType,
} from '../../types/admin/adminCatalogs';
import { IInfoTableProps } from '../react-app/components/common/admin/tables/InfoTable/InfoTable';
import { CheckMark } from '../react-app/components/common/admin/CheckMark';
import { AdminDocumentSourceView } from '../../types/admin/adminDocumentSource';
import { CatalogItemTypeDisplayName } from '../../types/catalog/catalog';
import { AutocompleteDropDown } from '../react-app/components/ColumnWithFilter/AutocompleteDropDown';


export const getSearchFilterColumnProps = <T extends unknown>(
    columnName: string
): ColumnProps<T> => {
    const filterProps: ColumnProps<T> = {
        filterDropdown: (props) => {
            return <InputFilterDropdown {...props} placeholder={columnName} />;
        },
        filterIcon: (filtered) => (
            <span className='anticon'>
                <i
                    className={classNames(FontAwesomeIcons.SEARCH)}
                    style={filtered ? { color: '#0097d8' } : undefined}
                />
            </span>
        ),
    };

    return filterProps;
};

export const getAutocompleteFilterColumnProps = <T extends unknown>(
    placeholder: string,
    options: any[],
): ColumnProps<T> => {
   
    const filterProps: ColumnProps<T> = {
        filterDropdown: (props) => {
            return <AutocompleteDropDown {...props} options={options} placeholder={placeholder}/>;
        },
        filterIcon: (filtered) => (
            <span className='anticon'>
                <i
                    className={classNames(FontAwesomeIcons.SEARCH)}
                    style={filtered ? { color: '#0097d8' } : undefined}
                />
            </span>
        ),
    };
    
    return filterProps;
};

export function getColumnFilterProps<T>(
    columnName: string,
    dataIndex: keyof T,
    filters?: string[]
): ColumnProps<T> {
    return {
        ...getSearchFilterColumnProps(columnName),
        onFilter: (value, record) => {
            const filterField = record[dataIndex];
            if (typeof filterField !== 'string') {
                return false;
            }
            return filterField
                .toLowerCase()
                .includes(value.toString().toLowerCase());
        },
        filteredValue: filters,
    };
}

export function getDatePickerColumnFilterProps<T>(
    dateType: DateFormat,
    filters?: string[],
    datePickerProps?: Partial<FilterDatePickerProps>
): ColumnProps<T> {
    return {
        filterDropdown: (props) => {
            return (
                <FilterDatePicker
                    dateType={dateType}
                    {...datePickerProps}
                    {...props}
                />
            );
        },
        filterIcon: (filtered) => (
            <span className='anticon'>
                <i
                    className={FontAwesomeIcons.CALENDAR}
                    style={filtered ? { color: '#0097d8' } : undefined}
                />
            </span>
        ),
        filteredValue: filters,
    };
}

export function getDatePickerOnClientFilterProps<T>(
    dateType: DateFormat,
    datePropName: keyof T,
    filters?: string[],
    datePickerProps?: Partial<FilterDatePickerProps>
): ColumnProps<T> {
    return {
        ...getDatePickerColumnFilterProps(dateType, filters, datePickerProps),
        onFilter: (_, record) => {
            if (!filters) {
                return false;
            }
            const [from, to] = filters;
            const date = record[datePropName];

            if (!date) {
                return false;
            }

            if (from && to) {
                return moment(date).isBetween(from, to);
            }
            if (!from && to) {
                return moment(date).isBefore(to);
            }
            if (!to && from) {
                return moment(date).isAfter(from);
            }

            return true;
        },
    };
}

export const getFilterItems = (
    items: Record<string, string> | Map<string, string> | string[]
): ColumnFilterItem[] => {
    const entries = Array.isArray(items)
        ? items.map((item) => [item, item])
        : items instanceof Map
        ? Array.from(items.entries())
        : Object.entries(items);

    return entries.map(([value, displayName]) => ({
        text: displayName,
        value,
    }));
};

export const getColumnValuesCatalog = (
    catalog: AdminCatalogModel,
    catalogDocument: AdminDocumentSourceView
): IInfoTableProps['columnValues'] => {
    return {
        'Тип каталога': {
            value: CatalogItemTypeDisplayName[
                catalog?.itemsType as CatalogItemType
            ],
        },
        Код: { value: catalog?.code },
        Описание: { value: catalog?.description },
        Источник: {
            value:
                catalogDocument?.displayTitle ||
                catalogDocument?.title ||
                catalogDocument?.code,
        },
        Индексация: { value: <CheckMark check={catalog?.index} /> },
        'Доступен пользователю': {
            value: <CheckMark check={catalog?.visibleToUser} />,
        },
        'Применять наследование': {
            value: <CheckMark check={catalog?.inherit} />,
        },
    };
};

