import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import { DocumentSlice, PendingRequestSlice } from '../../../../../types/stores';
import { useComputed } from '../../../hooks/useComputed';
import { Select } from 'antd';
import { SimilarityList } from '../Similarity';
import { Ners } from '../Ners';

export const DocumentsList: FC<{ documentId: string }> = observer(
	({ documentId }) => {
		const { documents: documentStore } = useStores<
			DocumentSlice & PendingRequestSlice
		>();
		const list = [
			{
				value: '1',
				label: 'По тексту',
			},
			{
				value: '2',
				label: 'По данным',
			},
		];
		
		const [selectDocumentType, onChangeSelectDocumentType] = useState<any>('1');
		const selectedFirstType = useComputed(() => selectDocumentType === '1', [selectDocumentType]);
		const [nerType, setNerType] = useState<any>()
		documentStore.selectNer.subscribe((value) => {
			if(value){
				onChangeSelectDocumentType('2');
			}
			setNerType(value)
		});
		
		
		return (
			<>
				<h3>Связанные документы</h3>
				<Select
					options={list}
					onChange={(value) => {
						onChangeSelectDocumentType(value);
						if (value === '1') {
							documentStore.resetFilters();
							documentStore.updateFilteredSimilarityList();
						} else {
							documentStore.fetchNer({
								id: documentId,
								from: 0,
								limit: 10,
							});
						}
						setNerType('')
					}
					}
					value={selectDocumentType}
				/>
				
				{selectedFirstType ?
					<SimilarityList documentId={documentId}/> :
					<Ners documentId={documentId} nerFilterValue={nerType}/>}
			
			</>
		);
	},
);

