import React, { FC, useCallback, useEffect, useState } from 'react';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { Select } from 'antd';
import Button from 'antd/es/button';
import classes from './AutocompleteDropDown.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcons } from '../../../../utils/constants';
import { observer } from 'mobx-react-lite';
import { useComputed } from '../../../hooks/useComputed';

export interface IAutocompleteDropDownProps extends FilterDropdownProps {
	placeholder?: string;
	options: OptionGroup<string>[];
}

export interface Option<T> {
	label: string;
	value: T;
}

export interface OptionGroup<T> {
	label: string;
	options: Option<T>[];
}

export const AutocompleteDropDown: FC<IAutocompleteDropDownProps> =
	observer((props) => {
		const {
			options,
			placeholder,
			selectedKeys,
			setSelectedKeys,
			confirm,
			clearFilters,
		} = props;
		const [inputVal, setInputVal] = useState<string>();
		
		const onInputChange = useCallback(
			(e: string) => {
				setSelectedKeys &&
				setSelectedKeys(e ? [e] : []);
			},
			[setSelectedKeys],
		);
		
		const confirmFilters = useCallback(() => {
			confirm && confirm();
		}, [confirm]);
		
		const reset = useCallback(() => {
			clearFilters && clearFilters();
		}, [clearFilters]);
		
		
		const filteredOptions = useComputed(() => {
			const list: OptionGroup<string>[] = [];
			
			options.forEach(opt => {
				const options = opt.options.filter(n => inputVal ? n.label.toLowerCase().includes(inputVal?.toLowerCase()) : true);
				if (options.length) {
					list.push({
						label: opt.label,
						options,
					});
				}
			});
			return list;
		}, [options, inputVal]);
		
		useEffect(() => {
			if (!selectedKeys.length) {
				setInputVal('');
			}
		}, [selectedKeys]);
		return (
			<div className={classes['dropdown-filter']}>
				
				<Select
					options={filteredOptions}
					className={classes['select']}
					placeholder={placeholder}
					value={selectedKeys && selectedKeys[0] as string}
					onChange={onInputChange}
					showSearch
					filterOption={_ => {
						return true;
					}}
					onSearch={(v) => {
						setInputVal(v);
					}}
				/>
				<Button
					className={classes['search-button']}
					type="primary"
					onClick={confirmFilters}
					icon={
						<i
							className={classNames(
								FontAwesomeIcons.SEARCH,
								classes['search-icon'],
							)}
						/>
					}
					size="small"
				>
					Поиск
				</Button>
				<Button
					className={classes['reset-button']}
					onClick={reset}
					size="small"
				>
					Сброс
				</Button>
			</div>
		
		);
	});
